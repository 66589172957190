//Theme = app theme
//Current page = 'Home' || 'Scenarios', etc. to tell NavBar which button is currently selected

import { Box, Button, Grid, Typography } from "@mui/material";
import InformationSectionListModel from "../../models/InformationSectionList.model";
import InformationSectionListItemModel from "../../models/InformationSectionListItem.model";
import ButtonPrimaryLightStyledCustomization from "../buttons/ButtonPrimaryLight";
import ButtonSecondaryStyledCustomization from "../buttons/ButtonSecondary";
import { useState } from "react";
import InformationSectionInformationModel from "../../models/InformationSectionInformation.model";
import PaperPrimaryStyledCustomization from "../paper/PaperPrimary";
import ReactPlayer from "react-player";
import PaperSecondaryStyledCustomization from "../paper/PaperSecondary";
import ButtonPrimary from "../buttons/ButtonPrimary";
import Dna from "../../assets/icons/Dna";
import Heart from "../../assets/icons/Heart";
import Body from "../../assets/icons/Body";
import Neurology from "../../assets/icons/Neurology";

//isWelsh/ setIsWelsh -  State management for showing english/welsh text
function InformationSection({
  theme,
  isWelsh,
  informationSectionListData,
}: any) {
  const [informationSectionList, setInformationSectionList] =
    useState<InformationSectionListModel>(informationSectionListData);
  const [selectedInformationTab, setSelectedInformationTab] =
    useState<InformationSectionInformationModel>(
      informationSectionListData.items[0].information
    );
  //Set prop value
  const handleHoverChange = (index: number) => {
    index = index + 1;
    let info_list = informationSectionList.items.filter(
      (val: any) => val.id !== index
    );
    let page = informationSectionList.items.find(
      (val: any) => val.id === index
    );

    if (page) {
      page.hovered = !page?.hovered;
      info_list.push(page);
      info_list.sort((a: any, b: any) => (a.id > b.id ? 1 : -1));
      setInformationSectionList({ items: info_list });
      console.log(informationSectionList);
    }
  };

  function setSelected(id: number) {
    let old_list: Array<InformationSectionListItemModel> =
      informationSectionList.items;
    let new_list: Array<InformationSectionListItemModel> = [];

    for (let i = 0; i < old_list.length; i++) {
      let info_data: InformationSectionListItemModel = old_list[i];

      //Set this to selected
      if (info_data.id === id) {
        setSelectedInformationTab(info_data.information);
        info_data.selected = true;
      } else {
        info_data.selected = false;
      }

      new_list.push(info_data);
    }
    let new_information_section_list: InformationSectionListModel = {
      items: new_list,
    };
    setInformationSectionList(new_information_section_list);
  }

  return (
    <>
      <Grid container item xs={12} sm={12} md={12} justifyContent={"center"}>
        <Grid container sx={{ paddingTop: "50px", paddingBottom: "50px" }}>
          {/* The section picker */}
          <Grid
            container
            item
            md={12}
            xs={12}
            sm={12}
            spacing={2}
            justifyContent={"center"}
          >
            {informationSectionList.items.map(
              (val: InformationSectionListItemModel, index: number) => {
                let image = val.image;
                return (
                  <Grid
                    container
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"space-evenly"}
                    item
                    xl={2.4}
                    lg={2.4}
                    md={2.36}
                    sm={4}
                    xs={6}
                  >
                    <ButtonPrimaryLightStyledCustomization
                      sx={
                        val.selected && val.hovered
                          ? {
                              backgroundColor:
                                theme.palette.secondary.main + " !important",
                              borderRadius: 2,
                              boxShadow: 3,
                              paddingLeft: "0px !important",
                              paddingRight: "0px !important",
                              paddingTop: "40px !important",
                              paddingBottom: "40px !important",
                            }
                          : !val.selected && val.hovered
                          ? {
                              backgroundColor:
                                theme.palette.primary.main + " !important",
                              borderRadius: 2,
                              boxShadow: 3,
                              paddingLeft: "0px !important",
                              paddingRight: "0px !important",
                              paddingTop: "40px !important",
                              paddingBottom: "40px !important",
                            }
                          : val.selected && !val.hovered
                          ? {
                              backgroundColor:
                                theme.palette.primary.main + " !important",
                              borderRadius: 2,
                              boxShadow: 3,
                              paddingLeft: "0px !important",
                              paddingRight: "0px !important",
                              paddingTop: "40px !important",
                              paddingBottom: "40px !important",
                            }
                          : {
                              backgroundColor:
                                theme.palette.secondary.main + " !important",
                              borderRadius: 2,
                              boxShadow: 3,
                              paddingTop: "40px !important",
                              paddingLeft: "0px !important",
                              paddingRight: "0px !important",
                              paddingBottom: "40px !important",
                            }
                      }
                      onClick={() => {
                        setSelected(val.id);
                      }}
                      onMouseEnter={() => {
                        handleHoverChange(index);
                      }}
                      onMouseLeave={() => {
                        handleHoverChange(index);
                      }}
                    >
                      {image === "Dna" ? (
                        val.selected && val.hovered ? (
                          <Dna
                            color={theme.palette.primary.main}
                            width={140}
                            height={80}
                          />
                        ) : val.selected && !val.hovered ? (
                          <Dna
                            color={theme.palette.secondary.main}
                            width={140}
                            height={80}
                          />
                        ) : !val.selected && val.hovered ? (
                          <Dna
                            color={theme.palette.secondary.main}
                            width={140}
                            height={80}
                          />
                        ) : (
                          <Dna
                            color={theme.palette.primary.main}
                            width={140}
                            height={80}
                          />
                        )
                      ) : image === "Heart" ? (
                        val.selected && val.hovered ? (
                          <Heart
                            color={theme.palette.primary.main}
                            width={140}
                            height={80}
                          />
                        ) : val.selected && !val.hovered ? (
                          <Heart
                            color={theme.palette.secondary.main}
                            width={140}
                            height={80}
                          />
                        ) : !val.selected && val.hovered ? (
                          <Heart
                            color={theme.palette.secondary.main}
                            width={140}
                            height={80}
                          />
                        ) : (
                          <Heart
                            color={theme.palette.primary.main}
                            width={140}
                            height={80}
                          />
                        )
                      ) : image === "Body" ? (
                        val.selected && val.hovered ? (
                          <Body
                            color={theme.palette.primary.main}
                            width={140}
                            height={80}
                          />
                        ) : val.selected && !val.hovered ? (
                          <Body
                            color={theme.palette.secondary.main}
                            width={140}
                            height={80}
                          />
                        ) : !val.selected && val.hovered ? (
                          <Body
                            color={theme.palette.secondary.main}
                            width={140}
                            height={80}
                          />
                        ) : (
                          <Body
                            color={theme.palette.primary.main}
                            width={140}
                            height={80}
                          />
                        )
                      ) : image === "Neurology" &&
                        val.selected &&
                        val.hovered ? (
                        <Neurology
                          color={theme.palette.primary.main}
                          width={140}
                          height={80}
                        />
                      ) : val.selected && !val.hovered ? (
                        <Neurology
                          color={theme.palette.secondary.main}
                          width={140}
                          height={80}
                        />
                      ) : !val.selected && val.hovered ? (
                        <Neurology
                          color={theme.palette.secondary.main}
                          width={140}
                          height={80}
                        />
                      ) : (
                        <Neurology
                          color={theme.palette.primary.main}
                          width={140}
                          height={80}
                        />
                      )}
                    </ButtonPrimaryLightStyledCustomization>

                    <Typography
                      variant={"h6"}
                      color={
                        val.selected
                          ? theme.palette.primary.main
                          : theme.palette.primary.dark
                      }
                      sx={{
                        marginTop: "10px",
                        fontSize: 14,
                        textTransform: "uppercase",
                      }}
                    >
                      {isWelsh ? val.welsh : val.english}
                    </Typography>
                  </Grid>
                );
              }
            )}
          </Grid>
        </Grid>

        {/* Primary info */}
        <Grid
          container
          item
          xs={11}
          md={11}
          lg={11}
          xl={11}
          spacing={2}
          justifyContent={"center"}
        >
          <Grid item xl={4} lg={4} md={3} sm={12} xs={12}>
            <PaperPrimaryStyledCustomization sx={{ padding: "20px 35px" }}>
              <>
                <Typography
                  variant="h6"
                  align="center"
                  color={theme.palette.text.primary}
                  sx={{ fontWeight: 700, paddingBottom: "15px" }}
                >
                  {isWelsh
                    ? selectedInformationTab.welsh_primary_info_title
                    : selectedInformationTab.english_primary_info_title}
                </Typography>
                <Typography
                  variant={"body1"}
                  color={theme.palette.secondary.main}
                  sx={{ marginBottom: "15px" }}
                >
                  {isWelsh
                    ? selectedInformationTab.welsh_primary_info_content1
                    : selectedInformationTab.english_primary_info_content1}
                </Typography>

                <Typography
                  variant={"body1"}
                  color={theme.palette.secondary.main}
                >
                  {isWelsh
                    ? selectedInformationTab.welsh_primary_info_content2
                    : selectedInformationTab.english_primary_info_content2}
                </Typography>
              </>
            </PaperPrimaryStyledCustomization>
          </Grid>

          <Grid
            item
            xl={7.5}
            lg={7.8}
            md={8.9}
            sm={12}
            xs={12}
            sx={{ height: { xs: "50%", md: "auto" } }}
          >
            <ReactPlayer
              url={selectedInformationTab.primary_video}
              height={"100%"}
              width={"100%"}
              style={{ borderRadius: "12px", overflow: "hidden" }}
            />
          </Grid>

          <Grid item md={6} sm={12}>
            <PaperSecondaryStyledCustomization
              sx={{
                paddingTop: " 20px",
                textAlign: { md: "left", xs: "center" },
              }}
            >
              <>
                <Typography
                  variant="h4"
                  color={theme.palette.text.secondary}
                  sx={{ fontWeight: 700, paddingBottom: "15px" }}
                >
                  {isWelsh
                    ? selectedInformationTab.welsh_further_info_left_title
                    : selectedInformationTab.english_further_info_left_title}
                </Typography>

                <Typography
                  variant={"body1"}
                  color={theme.palette.text.secondary}
                  sx={{ marginBottom: "15px" }}
                >
                  {isWelsh
                    ? selectedInformationTab.welsh_further_info_left_content
                    : selectedInformationTab.english_further_info_left_content}
                </Typography>
                {/* <div style={{display: "flex"}}> */}
                <ButtonPrimary onClick={() => {}}>
                  {isWelsh
                    ? selectedInformationTab.welsh_further_info_left_button
                    : selectedInformationTab.english_further_info_left_button}
                </ButtonPrimary>
                {/* </div> */}
              </>
            </PaperSecondaryStyledCustomization>
          </Grid>

          <Grid item lg={5.5} md={5.8} sm={12}>
            <PaperPrimaryStyledCustomization
              sx={{
                padding: "20px 35px",
                textAlign: { md: "left", xs: "center" },
              }}
            >
              <>
                <Typography
                  variant="h4"
                  color={theme.palette.text.primary}
                  sx={{ fontWeight: 800, paddingBottom: "15px" }}
                >
                  {isWelsh
                    ? selectedInformationTab.welsh_further_info_right_title
                    : selectedInformationTab.english_further_info_right_title}
                </Typography>
                <Typography
                  variant={"body1"}
                  color={theme.palette.secondary.main}
                  sx={{ marginBottom: "15px" }}
                >
                  {isWelsh
                    ? selectedInformationTab.welsh_further_info_right_content
                    : selectedInformationTab.english_further_info_right_content}
                </Typography>
              </>
            </PaperPrimaryStyledCustomization>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default InformationSection;
