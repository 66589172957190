//---------------------------------------------------------------------------------------------------------------
// Universal text
export var title = { english: "Virtus Tech", welsh: "Virtus Tech" };

//---------------------------------------------------------------------------------------------------------------
// Navigation Bar
export var homeNav = { english: "Home", welsh: "Virtus Tech", link: "/" };
export var ScenarioNav = {
  english: "Scenarios",
  welsh: "Virtus Tech",
  link: "/Shift-Scenario",
};
export var OpportunitiesNav = {
  english: "Opportunities",
  welsh: "Virtus Tech",
  link: "/Opportunities",
};
export var BlogNav = { english: "Blog", welsh: "Virtus Tech", link: "/Blog" };
export var Contact = {
  english: "Contact Us",
  welsh: "Virtus Tech",
  link: "/Contact-Us",
};

//---------------------------------------------------------------------------------------------------------------
// Home Page
export var footerText = {
  english:
    "Our DIGI SUITE of products opens new horizons  to the world through VR. We create new solutions  for learning, viewing, shopping and gathering key insights.Copyright 2021 VIRTUS TECH Ltd. All rights reserved.",
  welsh:
    "LOREM IPSUM DOLOR SIT AMET LOREM IPSUM DOLOR SIT AMET LOREM IPSUM DOLOR SIT AMET LOREM IPSUM DOLOR SIT AMET LOREM IPSUM DOLOR SIT AMET LOREM IPSUM DOLOR SIT AMET LOREM IPSUM DOLOR SIT AMET",
};
