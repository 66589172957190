//---------------------------------------------------------------------------------------------------------------
// Home Page; Landing page
export var HomeLandingTitle = {
  english: "LOREM IPSUM DOLOR SIT AMET",
  welsh: "LOREM IPSUM DOLOR SIT AMET",
};
export var HomeLandingText = {
  english:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
  welsh:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et doloremagna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
};
export var HomeLandingButton = {
  english: "Button Here",
  welsh: "Button Here",
  link: "",
};

//---------------------------------------------------------------------------------------------------------------
// Our Project Aims
export var HomeProjectAimsTitle = {
  english: "OUR PROJECT AIMS",
  welsh: "LOREM IPSUM DOLOR",
};
export var HomeProjectAimsText1 = {
  english:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
  welsh:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et doloremagna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
};
export var HomeProjectAimsText2 = {
  english:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
  welsh:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et doloremagna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
};

//---------------------------------------------------------------------------------------------------------------
// Blog
export var HomeBlogTitle = {
  english: "READ OUR BLOG POSTS",
  welsh: "LOREM IPSUM DOLOR",
};
export var HomeBlogText = {
  english:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
  welsh:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et doloremagna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
};
export var HomeBlogButton = {
  english: "View Blog",
  welsh: "Button Here",
  link: "/Blog",
};

//---------------------------------------------------------------------------------------------------------------
// Project Scenarios
export var HomeScenarioTitle = {
  english: "PROJECT SCENARIOS",
  welsh: "LOREM IPSUM DOLOR",
};

export var HomeScenarioTitle1 = {
  english: "SHIFT SCENARIO ",
  welsh: "LOREM IPSUM DOLOR",
};
export var HomeScenarioText1 = {
  english:
    "orem ipsum dolor sit amet, consectetur adipiscing elit, sed doeius mod tempor incididunt Loremi psum dolor sit amet, consectetur adipiscing. Lorem ipsum dolor sitamet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco",
  welsh:
    "orem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Lorem ipsum dolor sit amet, consectetur adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco",
};
export var HomeScenarioButton1 = {
  english: "Button Here",
  welsh: "LOREM IPSUM",
  link: "/Shift-Scenario",
};

export var HomeScenarioTitle2 = {
  english: "PATIENT JOURNEY ",
  welsh: "LOREM IPSUM DOLOR",
};
export var HomeScenarioText2 = {
  english:
    "orem ipsum dolor sit amet, consectetur adipiscing elit, sed doeius mod tempor incididunt Loremi psum dolor sit amet, consectetur adipiscing. Lorem ipsum dolor sitamet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco",
  welsh:
    "orem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Lorem ipsum dolor sit amet, consectetur adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco",
};
export var HomeScenarioButton2 = {
  english: "Button Here",
  welsh: "LOREM IPSUM",
  link: "/Patient-Journey",
};

export var HomeScenarioTitle3 = {
  english: "TIMED SCENARIO ",
  welsh: "LOREM IPSUM DOLOR",
};
export var HomeScenarioText3 = {
  english:
    "orem ipsum dolor sit amet, consectetur adipiscing elit, sed doeius mod tempor incididunt Loremi psum dolor sit amet, consectetur adipiscing. Lorem ipsum dolor sitamet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco",
  welsh:
    "orem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Lorem ipsum dolor sit amet, consectetur adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco",
};
export var HomeScenarioButton3 = {
  english: "Button Here",
  welsh: "LOREM IPSUM",
  link: "/Timed-Scenario",
};

export var ProjectPartnersTitle = {
  english: "PROJECT PARTNERS",
  welsh: "LOREM IPSUM DOLOR",
};

export var HomeCreatorsTitle = {
  english: "CREATORS PLATFORM ",
  welsh: "LOREM IPSUM DOLOR",
};
export var HomeCreatorsText1 = {
  english:
    "orem ipsum dolor sit amet, consectetur adipiscing elit, sed doeius mod tempor incididunt Loremi psum dolor sit amet, consectetur adipiscing. Lorem ipsum dolor sitamet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco",
  welsh:
    "orem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Lorem ipsum dolor sit amet, consectetur adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco",
};
export var HomeCreatorsText2 = {
  english:
    "orem ipsum dolor sit amet, consectetur adipiscing elit, sed doeius mod tempor incididunt Loremi psum dolor sit amet, consectetur adipiscing. Lorem ipsum dolor sitamet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco",
  welsh:
    "orem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Lorem ipsum dolor sit amet, consectetur adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco",
};
export var HomeCreatorsButton = {
  english: "Button Here",
  welsh: "LOREM IPSUM",
  link: "/Creator-Mode",
};
