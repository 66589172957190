import { Grid, Paper, Theme, Typography } from "@mui/material";
import Body from "../../assets/icons/Body";
import Dna from "../../assets/icons/Dna";
import Heart from "../../assets/icons/Heart";
import Neurology from "../../assets/icons/Neurology";

import ButtonPrimary from "../buttons/ButtonPrimary";
import ButtonSecondaryStyledCustomization from "../buttons/ButtonSecondary";
interface englishWelshText {
  english: string;
  welsh: string;
  link?: string;
}

export default function ScenarioCard(
  selected: boolean,
  image: string,
  title: englishWelshText,
  text: englishWelshText,
  button: englishWelshText,
  theme: Theme,
  isWelsh: boolean
) {
  return (
    <Grid item xs={12} md={4} sx={{maxWidth: '350px'}}>
      <Paper
        elevation={20}
        sx={{
          height: 650,
          backgroundColor: selected
            ? theme.palette.primary.main
            : theme.palette.secondary.main,
          marginBlock: 5,
          borderRadius: 10,
        }}
      >
        <Grid container direction={"column"} alignItems={'center'}>
          <Grid item xs={11} justifyContent={'center'} sx={{paddingTop: '40px !important',paddingBottom: '40px !important'}}>
            {image === 'heart' ? (
              <Heart height={150} color={selected ? theme.palette.secondary.main : theme.palette.primary.main} />
            ) : image === 'dna' ? (
              <Dna height={150} color={selected ? theme.palette.secondary.main : theme.palette.primary.main} />
            ) : image === 'body' ? (
              <Body height={150} color={selected ? theme.palette.secondary.main : theme.palette.primary.main} />
            ) : image === 'neurology' && (
              <Neurology height={150} color={selected ? theme.palette.secondary.main : theme.palette.primary.main} />
            )}
          </Grid>
          <Grid item xs={11}>
            <Typography
              variant="h6"
              align="center"
              color={
                selected
                  ? theme.palette.text.primary
                  : theme.palette.text.secondary
              }
              sx={{ fontWeight: 700 }}
            >
              {isWelsh ? title.welsh : title.english}
            </Typography>
          </Grid>

          <Grid item xs={10}>
            <Typography
              variant="body2"
              align="center"
              color={
                selected
                  ? theme.palette.text.primary
                  : theme.palette.text.secondary
              }
              sx={{ marginInline: 4, marginTop: 4, marginBottom: 8 }}
            >
              {isWelsh ? text.welsh : text.english}
            </Typography>
          </Grid>

          <Grid item xs={10}>
            {selected ? (
              <ButtonSecondaryStyledCustomization to={button.link}>
                {isWelsh ? button.welsh : button.english}
              </ButtonSecondaryStyledCustomization>
            ) : (
              <ButtonPrimary to={button.link}>
                {isWelsh ? button.welsh : button.english}
              </ButtonPrimary>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
