import * as React from "react";
import { Button, SvgIconProps, ButtonProps } from "@mui/material";
import { alpha, styled, SxProps, Theme } from "@mui/material/styles";
import { Link } from "react-router-dom";

const ButtonNav = styled(Button)<ButtonProps>(({ theme, height }: any) => ({
  height: height,
  color: "white",
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    color: "white !important",
  },
  padding: "10px 25px !important",
  borderRadius: "12px !important",
  marginRight: "5px !important",
  fontSize: "11px !important",
}));

interface StyledCustomizationProps {
  id?: string;
  sx?: any;
  icon?: React.ReactElement<SvgIconProps>;
  height?: Number;
  theme?: Theme;
  type?: "button" | "submit" | "reset" | undefined;
  onClick?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  onMouseOver?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  onMouseLeave?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  disabled?: boolean;
  children: React.ReactNode;
}

export default function ButtonNavStyledCustomization({
  id,
  icon,
  type,
  onClick,
  onMouseOver,
  onMouseLeave,
  sx,
  disabled,
  children,
}: StyledCustomizationProps) {
  return (
    <ButtonNav
      id={id}
      disableElevation
      type={type}
      variant="contained"
      startIcon={icon}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      disabled={disabled}
      sx={sx}
    >
      {children}
    </ButtonNav>
  );
}
