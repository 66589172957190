//---------------------------------------------------------------------------------------------------------------

import InformationSectionListModel from "../../models/InformationSectionList.model";

// PatientJourney Page; Landing page
export var PatientJourneyLandingTitle = {
  english: "PATIENT JOURNEY",
  welsh: "LOREM IPSUM DOLOR SIT AMET",
};
export var PatientJourneyLandingText = {
  english:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
  welsh:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et doloremagna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
};
export var PatientJourneyLandingButton = {
  english: "Button",
  welsh: "Button Here",
  link: "/Patient-Journey",
};

//ScenarioInformation data!
export const informationSectionListData: InformationSectionListModel = {items: [
  {
      id: 1,
      english: 'Handover',
      welsh: 'Trosglwyddo',
      image: 'Body',
      selected: true,
      hovered: false,
      information: {
          english_primary_info_title: "Handover",
          welsh_primary_info_title: "Trosglwyddo",
          english_primary_info_content1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          english_primary_info_content2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          welsh_primary_info_content1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          welsh_primary_info_content2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          primary_video: "https://youtu.be/Agp-BncZFrQ",
          english_further_info_left_title: "enlgish left title",
          welsh_further_info_left_title: "welsh left title",
          english_further_info_left_content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          welsh_further_info_left_content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          english_further_info_left_button: "english button",
          welsh_further_info_left_button: "welsh button",
          english_further_info_right_title: "english right title",
          welsh_further_info_right_title: "welsh right title",
          english_further_info_right_content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          welsh_further_info_right_content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
      }
  },
  {
      id: 2,
      english: 'Patient Info',
      welsh: 'Gwybodaeth i gleifion',
      image: 'Dna',
      selected: false,
      hovered: false,
      information: {
          english_primary_info_title: "Patient Information",
          welsh_primary_info_title: "Gwybodaeth i gleifion",
          english_primary_info_content1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          english_primary_info_content2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          welsh_primary_info_content1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          welsh_primary_info_content2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          primary_video: "https://www.youtube.com/embed/g1JLzAL28ps",
          english_further_info_left_title: "enlgish left title",
          welsh_further_info_left_title: "welsh left title",
          english_further_info_left_content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          welsh_further_info_left_content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          english_further_info_left_button: "english button",
          welsh_further_info_left_button: "welsh button",
          english_further_info_right_title: "english right title",
          welsh_further_info_right_title: "welsh right title",
          english_further_info_right_content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          welsh_further_info_right_content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
      }
  },
  {
      id: 3,
      english: 'Lorem Ipsum1',
      welsh: 'Trosglwyddo1',
      image: 'Heart',
      selected: false,
      hovered: false,
      information: {
          english_primary_info_title: "Lorem Ipsum1",
          welsh_primary_info_title: "Trosglwyddo",
          english_primary_info_content1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          english_primary_info_content2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          welsh_primary_info_content1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          welsh_primary_info_content2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          primary_video: "https://www.youtube.com/embed/w73EqXzfW7k",
          english_further_info_left_title: "enlgish left title",
          welsh_further_info_left_title: "welsh left title",
          english_further_info_left_content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          welsh_further_info_left_content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          english_further_info_left_button: "english button",
          welsh_further_info_left_button: "welsh button",
          english_further_info_right_title: "english right title",
          welsh_further_info_right_title: "welsh right title",
          english_further_info_right_content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          welsh_further_info_right_content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
      }
  },
  {
      id: 4,
      english: 'Lorem Ipsum2',
      welsh: 'Trosglwyddo2',
      image: 'Dna',
      selected: false,
      hovered: false,
      information: {
          english_primary_info_title: "Lorem Ipsum2",
          welsh_primary_info_title: "Trosglwyddo",
          english_primary_info_content1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          english_primary_info_content2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          welsh_primary_info_content1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          welsh_primary_info_content2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          primary_video: "https://www.youtube.com/embed/CvP5Dogzz0c",
          english_further_info_left_title: "enlgish left title",
          welsh_further_info_left_title: "welsh left title",
          english_further_info_left_content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          welsh_further_info_left_content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          english_further_info_left_button: "english button",
          welsh_further_info_left_button: "welsh button",
          english_further_info_right_title: "english right title",
          welsh_further_info_right_title: "welsh right title",
          english_further_info_right_content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          welsh_further_info_right_content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
      }
  },
  {
      id: 5,
      english: 'Lorem Ipsum3',
      welsh: 'Trosglwyddo3',
      image: 'Dna',
      selected: false,
      hovered: false,
      information: {
          english_primary_info_title: "Lorem Ipsum3",
          welsh_primary_info_title: "Trosglwyddo",
          english_primary_info_content1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          english_primary_info_content2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          welsh_primary_info_content1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          welsh_primary_info_content2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          primary_video: "https://www.youtube.com/watch?v=ozYcq2u94EY",
          english_further_info_left_title: "enlgish left title",
          welsh_further_info_left_title: "welsh left title",
          english_further_info_left_content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          welsh_further_info_left_content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          english_further_info_left_button: "english button",
          welsh_further_info_left_button: "welsh button",
          english_further_info_right_title: "english right title",
          welsh_further_info_right_title: "welsh right title",
          english_further_info_right_content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
          welsh_further_info_right_content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
      }
  },
]}