import * as React from "react";
import { Button, SvgIconProps, ButtonProps } from "@mui/material";

import { alpha, styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { Theme } from "@material-ui/core";

const ButtonPrimaryLight = styled(Button)<ButtonProps>(({ theme, height }: any) => ({
  height: height,
  backgroundColor: theme.palette.primary.light + " !important",
  color: "#fff" + " !important",
  "&:hover": {
    color: theme.palette.primary.light + " !important",
  },
  padding: "10px 25px !important",
  borderRadius: "12px" + " !important",
  marginRight: "5px !important",
  fontSize: "11px !important",
}));

interface StyledCustomizationProps {
  id?: string;
  sx?: any;
  height?: Number;
  icon?: React.ReactElement<SvgIconProps>;
  theme?: Theme,
  type?: "button" | "submit" | "reset" | undefined;
  onClick?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  onMouseEnter?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  onMouseLeave?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  to?: string;
  disabled?: boolean;
  children: React.ReactNode;
}

export default function ButtonPrimaryLightStyledCustomization({
  id,
  icon,
  type,
  onClick,
  onMouseEnter,
  onMouseLeave,
  to,
  sx,
  disabled,
  children,
}: StyledCustomizationProps) {
  return (
    <ButtonPrimaryLight
      id={id}
      disableElevation
      type={type}
      variant="contained"
      startIcon={icon}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disabled={disabled}
      sx={sx}
      // @ts-ignore
      to={to}
      component={to ? Link : "button"}
    >
      {children}
    </ButtonPrimaryLight>
  );
}
