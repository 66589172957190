import * as React from "react";
import { Paper, SvgIconProps, PaperProps } from "@mui/material";

import { alpha, styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

const PaperPrimary = styled(Paper)<PaperProps>((props: any) => ({
  borderRadius: '12px',
  backgroundColor: props.theme.palette.primary.main,
}));

interface StyledCustomizationProps {
  children: React.ReactNode;
  sx?: any,
}

export default function PaperPrimaryStyledCustomization({
  children,
  sx
}: StyledCustomizationProps) {
  return <PaperPrimary sx={sx}>{children}</PaperPrimary>;
}
