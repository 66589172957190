import { Grid, Menu, MenuItem, Typography } from "@mui/material";
import theme from "../../assets/theme/theme";
import ButtonFooterStyledCustomization from "../buttons/ButtonFooter";
import FooterImg from "../../assets/images/FooterImage.png";
import * as FooterText from "../../assets/texts/FooterText";
import { ArrowDropDown } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//-------------------------------------------------------------------------------------------------------------------------
// Footer
function AppFooter({ isWelsh }: any) {
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const navigate = useNavigate();

  //States to handle Scenario Dropdown
  const [scenarioElNav, setScenarioElNav] = React.useState<null | HTMLElement>(
    null
  );

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const [pages, setPages] = useState([
    {
      id: 1,
      name: "Scenarios",
      selected: false,
      link: "",
      type: "parent",
      parentId: "scenarios",
    },
    {
      id: 2,
      name: "Shift Scenario",
      selected: false,
      link: "/Shift-Scenario",
      type: "child",
      parentId: "scenarios",
    },
    {
      id: 3,
      name: "Patient Journey",
      selected: false,
      link: "/Patient-Journey",
      type: "child",
      parentId: "scenarios",
    },
    {
      id: 4,
      name: "Timed Scenario",
      selected: false,
      link: "/Timed-Scenario",
      type: "child",
      parentId: "scenarios",
    },
  ]);

  const handleCloseFooterMenu = (link: string) => {
    navigate(link);
    setAnchorElNav(null);
    setScenarioElNav(null);
    scrollToTop();
  };

  //Functions to handle scenario dropdown
  const handleOpenScenarioMenu = (event: React.MouseEvent<HTMLElement>) => {
    setScenarioElNav(event.currentTarget);
  };

  const handleCloseScenarioMenu = () => {
    setScenarioElNav(null);
  };

  return (
    <>
      <Grid
        container
        sx={{
          marginBlock: 10,
          borderTop: 4,
          borderColor: theme.palette.primary.main,
        }}
        justifyContent={"center"}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={FooterImg}
            style={{
              marginTop: 50,
            }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        display={{ xs: "none", md: "flex" }}
      >
        <Grid
          md={3}
          item
          style={{
            border: 0,
            borderRight: 3,
            borderColor: theme.palette.primary.main,
            borderStyle: "solid",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ButtonFooterStyledCustomization
            to={FooterText.homeNav.link}
            onClick={() => scrollToTop()}
          >
            {isWelsh ? FooterText.homeNav.welsh : FooterText.homeNav.english}
          </ButtonFooterStyledCustomization>
        </Grid>
        <Grid
          md={3}
          item
          style={{
            border: 0,
            borderRight: 3,
            borderColor: theme.palette.primary.main,
            borderStyle: "solid",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ButtonFooterStyledCustomization onClick={handleOpenScenarioMenu}>
            {isWelsh
              ? FooterText.ScenarioNav.welsh
              : FooterText.ScenarioNav.english}
            <ArrowDropDown />
          </ButtonFooterStyledCustomization>
          <Menu
            id="menu-footer"
            anchorEl={scenarioElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={Boolean(scenarioElNav)}
            onClose={handleCloseScenarioMenu}
            sx={{
              display: { xs: "none", md: "flex" },

              left: 0,
            }}
          >
            <Grid
              container
              flexDirection={"column"}
              sx={{ margin: 0, padding: "5px 10px" }}
            >
              {pages.map((page) => {
                return (
                  <>
                    <MenuItem
                      key={page.name}
                      onClick={() => {
                        handleCloseFooterMenu(page.link);
                      }}
                      style={
                        page.selected
                          ? {
                              borderLeft:
                                "4px solid " + theme.palette.primary.main,
                              paddingLeft: "10px",
                              justifyContent: "flex-start",
                              padding: "10px 20px",
                            }
                          : {
                              justifyContent: "flex-start",
                              padding: "10px 20px",
                              paddingLeft: "5px",
                            }
                      }
                    >
                      <Typography
                        textAlign="center"
                        color={
                          page.selected
                            ? "primary"
                            : theme.palette.text.secondary
                        }
                      >
                        {page.name}
                      </Typography>
                    </MenuItem>
                  </>
                );
              })}
            </Grid>
          </Menu>
        </Grid>
        <Grid
          md={3}
          item
          style={{
            border: 0,
            borderRight: 3,
            borderColor: theme.palette.primary.main,
            borderStyle: "solid",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ButtonFooterStyledCustomization
            to={FooterText.BlogNav.link}
            onClick={() => scrollToTop()}
          >
            {isWelsh ? FooterText.BlogNav.welsh : FooterText.BlogNav.english}
          </ButtonFooterStyledCustomization>
        </Grid>
        <Grid
          md={3}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ButtonFooterStyledCustomization
            to={FooterText.Contact.link}
            onClick={() => scrollToTop()}
          >
            {isWelsh ? FooterText.Contact.welsh : FooterText.Contact.english}
          </ButtonFooterStyledCustomization>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          marginBlock: 5,
        }}
        justifyContent={"center"}
      >
        <Grid xs={8}>
          <Typography color={theme.palette.text.secondary}>
            {isWelsh
              ? FooterText.footerText.welsh
              : FooterText.footerText.english}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default AppFooter;
