import React, { useState, useEffect } from "react";

interface IHeartProps {
  color?: string;
  width?: number;
  height?: number;
}

function Dna({ color, width, height }: IHeartProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "219.88"}
      height={height ? height : "219.88"}
      viewBox="0 0 115.549 189.08"
    >
      <path
        d="M128.549,22.963V6h-10.5V21.757H23.5V6H13V22.963A88.816,88.816,0,0,0,48.735,94.172a99.316,99.316,0,0,1,10.241-5.656A78.291,78.291,0,0,1,34.981,63.774h71.587A78.238,78.238,0,0,1,70.774,94.853l-.084.036q-.926.4-1.867.775a88.772,88.772,0,0,0-55.338,73.156H13V195.08H23.5V179.323h94.54V195.08h10.5V168.819h-.486a88.839,88.839,0,0,0-35.249-61.911,99.322,99.322,0,0,1-10.241,5.656,78.291,78.291,0,0,1,23.995,24.742H34.981a78.238,78.238,0,0,1,35.793-31.078l.084-.036q.927-.4,1.867-.775a88.772,88.772,0,0,0,55.824-82.454Zm-16.6,124.847H29.6a78.13,78.13,0,0,0-5.548,21.009h93.436A78.129,78.129,0,0,0,111.944,147.81ZM29.6,53.27h82.339a78.129,78.129,0,0,0,5.548-21.009H24.056A78.13,78.13,0,0,0,29.6,53.27Z"
        transform="translate(-13 -6)"
        fill={color ? color :"#0b3fe1"}
        fill-rule="evenodd"
      />
    </svg>
  );
}

export default Dna;
