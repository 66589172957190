//---------------------------------------------------------------------------------------------------------
// Imports
import "../App.css";
import { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import "../index.css";

// Image imports
import HomeLandingImage from "../assets/images/HomeLandingImage.png";
import HomeBlogImage from "../assets/images/HomeBlogImage.png";

// Text imports
import * as BlogText from "../assets/texts/BlogText";

// Import css files
import ReactPlayer from "react-player";

//---------------------------------------------------------------------------------------------------------
// Home page, landing, aims, blog, scenario, contributors
function Blog({ theme, setCurrentPage }: any) {
  const [isWelsh, setIsWelsh] = useState(false);

  const matches = useMediaQuery("(min-width:1000px)");

  useEffect(() => {
    setCurrentPage("Blog");
  }, []);

  return (
    <div className="App">
      {/* ----------------------------------------------------------------------------------------------------------------- */}
      {/* Landing Page */}
      <Grid
        item
        container
        spacing={2}
        xs={12}
        md={11}
        alignItems="center"
        sx={{ marginTop: "84px" }}
        style={{
          paddingTop: "25px",
          paddingBottom: "25px",
          overflow: "hidden",
          margin: "auto",
        }}
      >
        <Grid item container xs={12} md={6}>
          <Grid
            item
            xs={12}
            style={{
              position: "sticky",
              alignContent: "center",
            }}
          >
            <ReactPlayer
              alignItems="left"
              style={{ overflow: "hidden" }}
              height="300px"
              width="100%"
              url={"https://www.youtube.com/embed/UWNnGcUD_a0"}
            />
          </Grid>
        </Grid>

        <Grid item container xs={12} md={6}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              align="left"
              color={theme.palette.text.secondary}
              sx={{ fontWeight: 800, marginTop: 18 }}
            >
              {isWelsh
                ? BlogText.BlogLandingTitle.welsh
                : BlogText.BlogLandingTitle.english}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              align="left"
              color={theme.palette.text.secondary}
              sx={{}}
            >
              {isWelsh
                ? BlogText.BlogLandingDate.welsh
                : BlogText.BlogLandingDate.english}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              align="justify"
              color={theme.palette.text.secondary}
              sx={{ marginTop: 3 }}
            >
              {isWelsh
                ? BlogText.BlogLandingText1.welsh
                : BlogText.BlogLandingText1.english}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              align="justify"
              color={theme.palette.text.secondary}
              sx={{ marginTop: 3 }}
            >
              {isWelsh
                ? BlogText.BlogLandingText2.welsh
                : BlogText.BlogLandingText2.english}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              align="justify"
              color={theme.palette.text.secondary}
              sx={{ marginTop: 3 }}
            >
              {isWelsh
                ? BlogText.BlogLandingText3.welsh
                : BlogText.BlogLandingText3.english}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* ----------------------------------------------------------------------------------------------------------------- */}
      {/*Quote text */}
      <Grid container item xs={12} flexDirection={"column"}>
        <Grid
          item
          container
          justifyContent={"center"}
          style={{ backgroundColor: theme.palette.primary.main }}
        >
          <Grid
            item
            container
            xs={6}
            md={6}
            direction={"row"}
            textAlign={"center"}
            sx={{ marginTop: 3, marginBottom: 3 }}
          >
            <Grid item xs={12} justifyContent={"center"} alignItems={"center"}>
              <Typography
                variant="body1"
                align="justify"
                color={theme.palette.text.primary}
                sx={{ marginBlock: 2, fontWeight: 600 }}
                maxWidth="800px"
              >
                {isWelsh
                  ? BlogText.BlogQuoteText.welsh
                  : BlogText.BlogQuoteText.english}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* ----------------------------------------------------------------------------------------------------------------- */}
      {/* Values with accompanying text */}
      <Grid container item xs={12} flexDirection={"column"}>
        <Grid
          item
          container
          xs={12}
          md={12}
          spacing={2}
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ marginTop: 3, marginBottom: 3 }}
        >
          <Grid
            item
            xs={5}
            md={2}
            justifyContent={"center"}
            style={{
              padding: "0 10px",
              border: 0,
              borderRight: 4,
              borderColor: theme.palette.primary.dark,
              borderStyle: "solid",
            }}
          >
            <Typography
              variant="h4"
              color={theme.palette.primary.main}
              sx={{ fontWeight: 600 }}
            >
              {isWelsh
                ? BlogText.BlogValue1.welsh
                : BlogText.BlogValue1.english}
            </Typography>
            <Typography
              variant="body1"
              color={theme.palette.text.secondary}
              sx={{ fontWeight: 600 }}
            >
              {isWelsh
                ? BlogText.BlogValueText1.welsh
                : BlogText.BlogValueText1.english}
            </Typography>
          </Grid>

          <Grid
            item
            xs={5}
            md={2}
            justifyContent={"center"}
            style={{
              padding: "0 10px",
              border: 0,
              borderRight: 4,
              borderColor: theme.palette.primary.dark,
              borderStyle: "solid",
            }}
          >
            <Typography
              variant="h4"
              color={theme.palette.primary.main}
              sx={{ fontWeight: 600 }}
            >
              {isWelsh
                ? BlogText.BlogValue2.welsh
                : BlogText.BlogValue2.english}
            </Typography>
            <Typography
              variant="body1"
              color={theme.palette.text.secondary}
              sx={{ fontWeight: 600 }}
            >
              {isWelsh
                ? BlogText.BlogValueText2.welsh
                : BlogText.BlogValueText2.english}
            </Typography>
          </Grid>

          <Grid
            item
            xs={5}
            md={2}
            justifyContent={"center"}
            style={{
              padding: "0 10px",
              border: 0,
              borderRight: 4,
              borderColor: theme.palette.primary.dark,
              borderStyle: "solid",
            }}
          >
            <Typography
              variant="h4"
              color={theme.palette.primary.main}
              sx={{ fontWeight: 600 }}
            >
              {isWelsh
                ? BlogText.BlogValue3.welsh
                : BlogText.BlogValue3.english}
            </Typography>
            <Typography
              variant="body1"
              color={theme.palette.text.secondary}
              sx={{ fontWeight: 600 }}
            >
              {isWelsh
                ? BlogText.BlogValueText3.welsh
                : BlogText.BlogValueText3.english}
            </Typography>
          </Grid>

          <Grid
            item
            xs={5}
            md={2}
            justifyContent={"center"}
            style={{
              padding: "0 10px",
              border: 0,
              borderRight: 4,
              borderColor: theme.palette.primary.dark,
              borderStyle: "solid",
            }}
          >
            <Typography
              variant="h4"
              color={theme.palette.primary.main}
              sx={{ fontWeight: 600 }}
            >
              {isWelsh
                ? BlogText.BlogValue4.welsh
                : BlogText.BlogValue4.english}
            </Typography>
            <Typography
              variant="body1"
              color={theme.palette.text.secondary}
              sx={{ fontWeight: 600 }}
            >
              {isWelsh
                ? BlogText.BlogValueText4.welsh
                : BlogText.BlogValueText4.english}
            </Typography>
          </Grid>

          <Grid
            item
            xs={5}
            md={2}
            justifyContent={"center"}
            style={{ padding: "0 10px" }}
          >
            <Typography
              variant="h4"
              color={theme.palette.primary.main}
              sx={{ fontWeight: 600 }}
            >
              {isWelsh
                ? BlogText.BlogValue5.welsh
                : BlogText.BlogValue5.english}
            </Typography>
            <Typography
              variant="body1"
              color={theme.palette.text.secondary}
              sx={{ fontWeight: 600 }}
            >
              {isWelsh
                ? BlogText.BlogValueText5.welsh
                : BlogText.BlogValueText5.english}
            </Typography>
          </Grid>
        </Grid>
        {/* ----------------------------------------------------------------------------------------------------------------- */}
        {/* Final content area */}

        <Grid
          item
          container
          spacing={1}
          xs={12}
          md={11}
          justifyContent="space-evenly"
          alignItems="center"
          sx={{ marginTop: "84px" }}
          style={{
            paddingTop: "25px",
            paddingBottom: "25px",
            overflow: "hidden",
            margin: "auto",
          }}
        >
          <Grid item container xs={12} md={6}>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                align="justify"
                color={theme.palette.text.secondary}
                sx={{ marginTop: 3 }}
              >
                {isWelsh
                  ? BlogText.BlogLandingText1.welsh
                  : BlogText.BlogLandingText1.english}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                align="justify"
                color={theme.palette.text.secondary}
                sx={{ marginTop: 3 }}
              >
                {isWelsh
                  ? BlogText.BlogLandingText2.welsh
                  : BlogText.BlogLandingText2.english}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                align="justify"
                color={theme.palette.text.secondary}
                sx={{ marginTop: 3 }}
              >
                {isWelsh
                  ? BlogText.BlogLandingText3.welsh
                  : BlogText.BlogLandingText3.english}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={5}>
            {/* Image to show on larger screens */}
            <Grid
              item
              xs={12}
              sm={12}
              md={9}
              sx={{ display: { xs: "none", md: "block" } }}
              style={{
                position: "relative",
                marginTop: "-40px",
                marginBottom: "-130px",
              }}
            >
              <img
                src={HomeLandingImage}
                style={{
                  width: "110%",
                  marginLeft: "58px",
                }}
                alt="Landing image of doctor"
              />
            </Grid>
            {/* Image to show on smaller screens */}
            <Grid
              item
              xs={12}
              md={9}
              sx={{ display: { xs: "block", md: "none" } }}
              style={{ position: "relative", marginBottom: "-110px" }}
            >
              <img
                src={HomeLandingImage}
                style={{
                  width: "100%",
                  marginLeft: "58px",
                }}
                alt="Landing image of doctor"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Blog;
