import React, { useState, useEffect } from "react";

interface IHeartProps {
  color?: string;
  width?: number;
  height?: number;
}


function Body({ color, width, height }: IHeartProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "219.88"}
      height={height ? height : "219.88"}
      viewBox="0 0 155.642 199.357"
    >
      <g transform="translate(-8.999 -4)">
        <path
          d="M49.815,53.3A18.489,18.489,0,1,0,31.326,34.815,18.489,18.489,0,0,0,49.815,53.3Zm0,12.326A30.815,30.815,0,1,0,19,34.815,30.815,30.815,0,0,0,49.815,65.63Z"
          transform="translate(37.005)"
          fill={color ? color :"#0b3fe1"}
          fill-rule="evenodd"
        />
        <path
          d="M52.446,37.67c4.894.834,8.435,4.662,8.435,9.12v96.179a4.937,4.937,0,0,0,5.188,4.642h.45a5,5,0,0,0,5.167-4.222l4.759-46.837c.015-5.117,4.656-9.259,10.376-9.259S97.181,91.434,97.2,96.552l4.759,46.837a5,5,0,0,0,5.167,4.222h.45a4.937,4.937,0,0,0,5.188-4.642v-96c0-4.458,3.542-8.287,8.437-9.12,9.11-1.55,18.742-3.7,29.329-6.461a4.59,4.59,0,0,0,3.529-5.755,5.3,5.3,0,0,0-6.432-3.157c-24.041,6.269-42.485,9.17-60.78,9.1-18.313-.074-36.78-3.128-60.889-9.113a5.287,5.287,0,0,0-6.386,3.232A4.6,4.6,0,0,0,23.18,31.4C33.727,34.019,43.339,36.119,52.446,37.67ZM81.518,146.694c-1.833,5.929-7.9,10.2-15,10.2h-.45c-8.6,0-15.563-6.234-15.563-13.925V46.79c-9.472-1.613-19.375-3.782-30.1-6.444C12.123,38.289,7.272,30.615,9.57,23.2S20.445,11.454,28.728,13.51c23.705,5.885,41.165,8.707,58.161,8.775,16.941.068,34.334-2.6,57.83-8.726,8.252-2.152,16.891,2.089,19.3,9.472S161.68,38.145,153.428,40.3c-10.812,2.819-20.777,5.05-30.294,6.669v96c0,7.691-6.968,13.925-15.563,13.925h-.45c-7.1,0-13.165-4.271-15-10.2a12.8,12.8,0,0,1-.4-1.675q-.065-.391-.105-.79L86.863,97.393q-.041-.409-.043-.816,0,.407-.043.816l-4.759,46.837q-.04.4-.105.79A12.805,12.805,0,0,1,81.518,146.694Z"
          transform="translate(0 46.463)"
          fill={color ? color :"#0b3fe1"}
          fill-rule="evenodd"
        />
      </g>
    </svg>
  );
}

export default Body;
