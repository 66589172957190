import * as React from "react";
import { AppBar, SvgIconProps, AppBarProps } from "@mui/material";
import { alpha, styled, SxProps, Theme } from "@mui/material/styles";
import { Link } from "react-router-dom";

const AppNavBar = styled(AppBar)<AppBarProps>(({theme}: any) => ({
  padding: '20px 40px',
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'space-between',
  color: theme.palette.secondary.main,
  backgroundColor: 'white',
}));

interface StyledCustomizationProps {
  id?: string;
  sx?: SxProps<Theme>;
  children: React.ReactNode;
}

export default function AppNavBarStyledCustomization({
  id,
  sx,
  children,
}: StyledCustomizationProps) {
  return (
    <AppNavBar
      id={id}
      sx={sx}
    >
      {children}
    </AppNavBar>
  );
}
