//---------------------------------------------------------------------------------------------------------------
// Contact Us; Landing page
export var ContactUsLandingTitle = {
  english: "LOREM IPSUM DOLOR SIT AMET",
  welsh: "LOREM IPSUM DOLOR SIT AMET",
};
export var ContactUsLandingText = {
  english:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
  welsh:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et doloremagna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat",
};

//---------------------------------------------------------------------------------------------------------------
// Contact Us; links
export var ContactUsLinkMail = {
  english: "VirtusTech.com",
  welsh: "LOREM IPSUM DOLOR.com",
  link: "/",
};
export var ContactUsLinkPhone = {
  english: "072486 489 757",
  welsh: "LOREM IPSUM DOLOR.com",
  link: "/",
};
export var ContactUsLinkLocation = {
  english: "13 Tramshed tech cardiff",
  welsh: "LOREM IPSUM DOLOR.com",
  link: "/",
};

//---------------------------------------------------------------------------------------------------------------
// Contact Us; form
export var ContactUsFormTitle = {
  english: "Send us a message",
  welsh: "LOREM IPSUM DOLOR.com",
};
export var ContactUsFormName = {
  english: "First & Last Name",
  welsh: "LOREM IPSUM DOLOR.com",
  placeHolderEnglish: "Your name",
  placeHolderWelsh: "LOREM IPSUM",
};
export var ContactUsFormPhone = {
  english: "Phone Number",
  welsh: "LOREM IPSUM DOLOR.com",
  placeHolderEnglish: "Your name",
  placeHolderWelsh: "LOREM IPSUM",
};
export var ContactUsFormEmail = {
  english: "Email Address",
  welsh: "LOREM IPSUM DOLOR.com",
  placeHolderEnglish: "Your name",
  placeHolderWelsh: "LOREM IPSUM",
};
export var ContactUsFormOrg = {
  english: "Health Board / Uni (Optional)",
  welsh: "LOREM IPSUM DOLOR.com",
  placeHolderEnglish: "Your name",
  placeHolderWelsh: "LOREM IPSUM",
};
export var ContactUsFormMessage = {
  english: "Message",
  welsh: "LOREM IPSUM DOLOR.com",
  placeHolderEnglish: "Your name",
  placeHolderWelsh: "LOREM IPSUM",
};
export var ContactUsFormError = {
  english: "Fields required",
  welsh: "LOREM IPSUM DOLOR.com",
};
export var ContactUsFormSubmit = {
  english: "Submit",
  welsh: "LOREM",
  link: "/",
};
