import * as React from "react";
import { Button, SvgIconProps, ButtonProps } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { Theme } from "@material-ui/core";

const ButtonSecondary = styled(Button)<ButtonProps>(
  ({ theme, height }: any) => ({
    height: height + " !important",
    color: theme.palette.primary.main + " !important",
    backgroundColor: "white" + " !important",
    "&:hover": {
      backgroundColor: theme.palette.primary.main + " !important",
      color: "#fff" + " !important",
    },
    padding: "10px 25px !important",
    borderRadius: "12px" + " !important",
    marginRight: "5px !important",
    fontSize: "11px !important",
  })
);

interface StyledCustomizationProps {
  id?: string;
  sx?: any;
  icon?: React.ReactElement<SvgIconProps>;
  height?: Number;
  secondaryColor?: string;
  theme?: Theme;
  type?: "button" | "submit" | "reset" | undefined;
  onClick?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  onMouseEnter?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  onMouseLeave?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  to?: string;
  disabled?: boolean;
  children: React.ReactNode;
}

export default function ButtonSecondaryStyledCustomization({
  id,
  icon,
  type,
  onClick,
  onMouseEnter,
  onMouseLeave,
  secondaryColor,
  to,
  sx,
  disabled,
  children,
}: StyledCustomizationProps) {
  return (
    <ButtonSecondary
      id={id}
      disableElevation
      type={type}
      variant="contained"
      startIcon={icon}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disabled={disabled}
      sx={sx}
      // @ts-ignore
      to={to}
      // style={
      //   secondaryColor
      //     ? { "&:hover": { backgroundColor: secondaryColor + " !important" } }
      //     : ""
      // }
      component={to ? Link : "button"}
    >
      {children}
    </ButtonSecondary>
  );
}
