export var BlogLandingTitle = {
  english: "LOREM IPSUM DOLOR SIT AMET ",
  welsh: "LOREM IPSUM DOLOR SIT AMET",
};

export var BlogLandingDate = {
  english: "OCTOBER 2021",
  welsh: "OCTOBER 2021",
};

export var BlogLandingText1 = {
  english:
    "orem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Lorem ipsum dolor sit amet, consectetur adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
  welsh:
    "orem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Lorem ipsum dolor sit amet, consectetur adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
};

export var BlogLandingText2 = {
  english:
    "orem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Lorem ipsum dolor sit amet, consectetur adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
  welsh:
    "orem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Lorem ipsum dolor sit amet, consectetur adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
};

export var BlogLandingText3 = {
  english:
    "orem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Lorem ipsum dolor sit amet, consectetur adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
  welsh:
    "orem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Lorem ipsum dolor sit amet, consectetur adipiscing. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
};

export var BlogQuoteText = {
  english:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  welsh:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
};

export var BlogValue1 = {
  english: "35+",
  welsh: "35+",
};
export var BlogValueText1 = {
  english: "Lorem Ipsum Dolor Sit Amet, Consectetur",
  welsh: "Lorem Ipsum Dolor Sit Amet, Consectetur",
};

export var BlogValue2 = {
  english: "300+",
  welsh: "300+",
};
export var BlogValueText2 = {
  english: "Lorem Ipsum Dolor Sit Amet, Consectetur",
  welsh: "Lorem Ipsum Dolor Sit Amet, Consectetur",
};

export var BlogValue3 = {
  english: "30%",
  welsh: "30%",
};
export var BlogValueText3 = {
  english: "Lorem Ipsum Dolor Sit Amet, Consectetur",
  welsh: "Lorem Ipsum Dolor Sit Amet, Consectetur",
};

export var BlogValue4 = {
  english: "20+",
  welsh: "20+",
};
export var BlogValueText4 = {
  english: "Lorem Ipsum Dolor Sit Amet, Consectetur",
  welsh: "Lorem Ipsum Dolor Sit Amet, Consectetur",
};

export var BlogValue5 = {
  english: "13+",
  welsh: "13+",
};
export var BlogValueText5 = {
  english: "Lorem Ipsum Dolor Sit Amet, Consectetur",
  welsh: "Lorem Ipsum Dolor Sit Amet, Consectetur",
};
