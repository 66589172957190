//---------------------------------------------------------------------------------------------------------
// Imports
import "../App.css";
import { useState } from "react";
import { Button, Grid, Paper, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import { makeStyles } from "@material-ui/styles";

// Component imports
import NavBar from "../components/navBar/NavBar";
import ScenarioCard from "../components/homeCards/ScenarioCard";
import CreatorCard from "../components/homeCards/CreatorCard";
import ButtonPrimary from "../components/buttons/ButtonPrimary";
import ButtonSecondaryDarkStyledCustomization from "../components/buttons/ButtonSecondaryDark";
import PaperPrimary from "../components/paper/PaperPrimary";

//Image imports
import HomeLandingImage from "../assets/images/HomeLandingImage.png";
import HomeBlogImage from "../assets/images/HomeBlogImage.png";

import * as HomeText from "../assets/texts/HomeText";
import PaperSecondaryStyledCustomization from "../components/paper/PaperSecondary";
import { typography } from "@mui/system";
import { useEffect } from "react";

import Neurology from "../assets/images/neurology.svg";

import CardiffUniversityImg from "../assets/images/collaborators/CardiffUniversity.png";
import CardiffValeImg from "../assets/images/collaborators/CardiffVale.png";
import EuropImg from "../assets/images/collaborators/Europ.png";
import HiwImg from "../assets/images/collaborators/Hiw.jpg";
import HubImg from "../assets/images/collaborators/Hub.png";
import SwanseaImg from "../assets/images/collaborators/Swansea.jpg";
import UwtsdImg from "../assets/images/collaborators/Uwtsd.png";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

// Handle hover color of blog button.
const buttonStyle = makeStyles((theme?: any) => ({
  button: {
    "&hover": {
      backgroundColor: "#031342",
    },
  },
}));

//---------------------------------------------------------------------------------------------------------
// Home page, landing, aims, blog, scenario, contributors
function Home({ theme, setCurrentPage }: any) {
  const classes = buttonStyle();
  const [isWelsh, setIsWelsh] = useState(false);
  const [collaborators, setCollaborators] = useState([
    { image: CardiffUniversityImg, height: 130 },
    { image: CardiffValeImg, height: 130 },
    { image: EuropImg, height: 130 },
    { image: HiwImg, height: 100 },
    { image: HubImg, height: 90 },
    { image: SwanseaImg, height: 130 },
    { image: UwtsdImg, height: 100 },
  ]);
  const matches = useMediaQuery("(min-width:1000px)");

  useEffect(() => {
    setCurrentPage("Home");
  }, []);

  return (
    <div className="App">
      {/* ----------------------------------------------------------------------------------------------------------------- */}
      {/* Landing Page */}
      <Grid
        container
        item
        xs={12}
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ marginTop: "84px", paddingLeft: { xs: "0", md: "40px" } }}
        style={{
          paddingTop: "25px",
          paddingBottom: "25px",
          width: "100vw",
          overflow: "hidden",
        }}
      >
        <Grid item xs={7} md={4}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              align="left"
              color={theme.palette.text.secondary}
              sx={{ fontWeight: 800, marginTop: 18 }}
            >
              {isWelsh
                ? HomeText.HomeLandingTitle.welsh
                : HomeText.HomeLandingTitle.english}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              align="justify"
              color={theme.palette.text.secondary}
              sx={{ marginTop: 3 }}
            >
              {isWelsh
                ? HomeText.HomeLandingText.welsh
                : HomeText.HomeLandingText.english}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} style={{ marginBlock: 15 }}></Grid>
        </Grid>
        {/* Image to show on larger screens */}
        <Grid
          item
          xs={12}
          md={8}
          sx={{ display: { xs: "none", md: "block" } }}
          style={{
            position: "relative",
            marginTop: "-40px",
            marginBottom: "-130px",
          }}
        >
          <img
            src={HomeLandingImage}
            style={{
              width: "110%",
              marginLeft: "58px",
            }}
            alt="Landing image of doctor"
          />
        </Grid>
        {/* Image to show on smaller screens */}
        <Grid
          item
          xs={12}
          md={8}
          sx={{ display: { xs: "block", md: "none" } }}
          style={{ position: "relative", marginBottom: "-110px" }}
        >
          <img
            src={HomeLandingImage}
            style={{
              width: "100%",
              marginLeft: "58px",
            }}
            alt="Landing image of doctor"
          />
        </Grid>
      </Grid>

      {/* ----------------------------------------------------------------------------------------------------------------- */}
      {/* Project Aims */}
      <Grid
        container
        xs={12}
        justifyContent="space-evenly"
        alignItems="center"
        style={{
          backgroundColor: theme.palette.primary.dark,
          marginTop: 5,
        }}
      >
        <Grid item xs={11} sm={9}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              color={theme.palette.text.secondary}
              sx={{ fontWeight: 800, marginTop: 10 }}
              textAlign="center"
            >
              {isWelsh
                ? HomeText.HomeProjectAimsTitle.welsh
                : HomeText.HomeProjectAimsTitle.english}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              sx={{ marginTop: 5 }}
              textAlign="justify"
            >
              {isWelsh
                ? HomeText.HomeProjectAimsText1.welsh
                : HomeText.HomeProjectAimsText1.english}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              sx={{ marginTop: 3, marginBottom: 10 }}
              textAlign="justify"
            >
              {isWelsh
                ? HomeText.HomeProjectAimsText2.welsh
                : HomeText.HomeProjectAimsText2.english}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* ----------------------------------------------------------------------------------------------------------------- */}
      {/* Blog Posts */}
      <Grid container item xs={12} flexDirection={"column"}>
        {/* Paper to create buffer around the image */}
        <PaperSecondaryStyledCustomization
          sx={{
            height: 50,
            backgroundColor: theme.palette.primary.dark,
            borderRadius: 0,
            boxShadow: "none",
          }}
        />
        <Grid
          item
          container
          justifyContent={"center"}
          style={{ backgroundColor: theme.palette.primary.main }}
        >
          <Grid
            item
            xs={11}
            lg={5}
            style={{
              marginTop: -20,
              marginBottom: -100,
              maxWidth: "40vw",
            }}
          >
            <img
              style={{
                position: "relative",
                marginTop: "-65px",
                width: "100%",
                maxWidth: "600px",
              }}
              src={HomeBlogImage}
            />
          </Grid>
          <Grid
            item
            container
            xs={10}
            lg={5}
            direction={"row"}
            justifyContent={"left"}
            alignItems={"center"}
          >
            <Grid item xs={12} lg={9}>
              <Typography
                variant="h4"
                color={theme.palette.text.primary}
                sx={{ fontWeight: 700, marginTop: 10 }}
              >
                {isWelsh
                  ? HomeText.HomeBlogTitle.welsh
                  : HomeText.HomeBlogTitle.english}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                align="justify"
                color={theme.palette.text.primary}
                sx={{ marginBlock: 3 }}
              >
                {isWelsh
                  ? HomeText.HomeBlogText.welsh
                  : HomeText.HomeBlogText.english}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={4} style={{ marginBottom: 35 }}>
              <ButtonSecondaryDarkStyledCustomization
                to={HomeText.HomeBlogButton.link}
              >
                {isWelsh
                  ? HomeText.HomeBlogButton.welsh
                  : HomeText.HomeBlogButton.english}
              </ButtonSecondaryDarkStyledCustomization>
            </Grid>
          </Grid>
          <Grid item lg={1} />
        </Grid>
        <PaperSecondaryStyledCustomization
          sx={{ height: { xs: 0, md: 50 }, boxShadow: "none" }}
        />
      </Grid>

      {/* ----------------------------------------------------------------------------------------------------------------- */}
      {/* Scenarios */}
      <Typography
        variant="h4"
        align="center"
        color={theme.palette.text.secondary}
        sx={{ fontWeight: 800, marginBlock: 10 }}
      >
        {isWelsh
          ? HomeText.HomeScenarioTitle.welsh
          : HomeText.HomeScenarioTitle.english}
      </Typography>
      <Grid container justifyContent={"center"}>
        <Grid
          container
          item
          xs={10}
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          {/* Spacer */}
          {/* Shift Scenario card */}
          {ScenarioCard(
            true,
            "body",
            HomeText.HomeScenarioTitle1,
            HomeText.HomeScenarioText1,
            HomeText.HomeScenarioButton1,
            theme,
            isWelsh
          )}
          {/* Patient journey card */}
          {ScenarioCard(
            false,
            "dna",
            HomeText.HomeScenarioTitle2,
            HomeText.HomeScenarioText2,
            HomeText.HomeScenarioButton2,
            theme,
            isWelsh
          )}
          {/* Time Scenario */}
          {ScenarioCard(
            false,
            "heart",
            HomeText.HomeScenarioTitle3,
            HomeText.HomeScenarioText3,
            HomeText.HomeScenarioButton3,
            theme,
            isWelsh
          )}
        </Grid>
      </Grid>

      <Grid container xs={12} justifyContent={"space-evenly"}>
        {/* Creator platform card for large screen */}
        <Grid container xs={11} md={8}>
          <Paper
            elevation={20}
            sx={{
              background: "white",
              marginBlock: 5,
              borderRadius: 10,
              overflow: "hidden",
            }}
          >
            {CreatorCard(HomeText, "neurology", theme, isWelsh)}
          </Paper>
        </Grid>

        {/* ----------------------------------------------------------------------------------------------------------------- */}
        {/* Collaborators */}

        <Grid xs={10} style={{ width: "90%", margin: 15 }}>
          <Typography
            variant="h4"
            align="center"
            color={theme.palette.text.secondary}
            sx={{ fontWeight: 800, marginBlock: 10 }}
          >
            {isWelsh
              ? HomeText.ProjectPartnersTitle.welsh
              : HomeText.ProjectPartnersTitle.english}
          </Typography>
          <Slider
            slidesToShow={matches ? 3 : 1}
            {...{
              dots: true,
              infinite: true,
              speed: 1200,
              slidesToScroll: 1,
              arrows: true,
              autoplay: true,
              autoplaySpeed: 3500,
            }}
            slidesPerRow={1}
          >
            {collaborators.map(({ image, height }: any) => (
              <div>
                <img
                  src={image}
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    height: height,
                  }}
                />
              </div>
            ))}
          </Slider>
        </Grid>
      </Grid>
    </div>
  );
}

export default Home;
