import { Theme, createTheme } from "@mui/material/styles";

const primaryColor: string = "#0B41DC";
const primaryLightColor: string = process.env.REACT_APP_PRIMARY_LIGHT
  ? process.env.REACT_APP_PRIMARY_LIGHT
  : "#0B7EE1";
const secondaryColor: string = process.env.REACT_APP_SECONDARY_COLOR
  ? process.env.REACT_APP_SECONDARY_COLOR
  : "#fcfcfc";
const secondaryDarkColor: string = process.env.REACT_APP_SECONDARY_COLOR
  ? process.env.REACT_APP_SECONDARY_COLOR
  : "#031342 ";

const ternaryColor: string = process.env.REACT_APP_TERNARY_COLOR
  ? process.env.REACT_APP_TERNARY_COLOR
  : "#DDDDDD";
const primaryText: string = process.env.REACT_APP_TEXT_PRIMARY
  ? process.env.REACT_APP_TEXT_PRIMARY
  : "#fcfcfc";
const secondaryText: string = process.env.REACT_APP_TEXT_SECONDARY
  ? process.env.REACT_APP_TEXT_SECONDARY
  : "#19243F";

const AppTheme: Theme = createTheme({
  palette: {
    text: {
      primary: primaryText,
      secondary: secondaryText,
    },
    primary: {
      main: primaryColor,
      light: primaryLightColor,
      dark: ternaryColor,
    },
    secondary: {
      main: secondaryColor,
      dark: secondaryDarkColor,
    },
    background: {
      default: secondaryColor,
      paper: secondaryColor,
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        body1: {
          color: primaryText,
        },
        body2: {
          color: secondaryText,
        },
        h4: {
          fontWeight: 800,
          textTransform: "uppercase",
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
  },
});

export default AppTheme;
