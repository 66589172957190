import { Grid, Typography } from "@mui/material";
import "../App.css";
import ButtonPrimary from "../components/buttons/ButtonPrimary";
import ScenarioLandingImage from "../assets/images/ScenarioLandingImage.png";

import * as ScenarioText from "../assets/texts/ShiftScenarioText";
import { useEffect } from "react";
import InformationSection from "../components/scenarios/InformationSection";
import { useNavigate } from "react-router-dom";

const localStyles = {
  defaultPadding: {
    paddingTop: "25px",
    paddingBottom: "25px",
    width: "100vw",
    overflow: "hidden",
  },
  scenarioLandingImage: {
    position: "relative",
    marginTop: "-40px",
    marginBottom: "-130px",
  },
};

function ShiftScenario({ theme, isWelsh, setCurrentPage }: any) {
  const navigate = useNavigate();

  function handleClickedLink() {
    navigate(ScenarioText.ScenarioLandingButton.link);
  }

  useEffect(() => {
    setCurrentPage("Shift Scenario");
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="App" style={{ marginBottom: "350px" }}>
      {/* ----------------------------------------------------------------------------------------------------------------- */}
      {/* Landing Page */}
      <Grid
        container
        item
        xs={12}
        spacing={2}
        justifyContent="space-evenly"
        alignItems="center"
        sx={{ marginTop: "84px", paddingLeft: { xs: "0", xl:'2%', lg: "1%", md: '1%' }}}
        style={localStyles.defaultPadding}
      >
        <Grid item xs={8} md={5}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              align="left"
              color={theme.palette.text.secondary}
              sx={{ fontWeight: 800 }}
            >
              {isWelsh
                ? ScenarioText.ScenarioLandingTitle.welsh
                : ScenarioText.ScenarioLandingTitle.english}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              align="left"
              color={theme.palette.text.secondary}
              sx={{ marginTop: 5 }}
            >
              {isWelsh
                ? ScenarioText.ScenarioLandingText.welsh
                : ScenarioText.ScenarioLandingText.english}
            </Typography>
          </Grid>
        </Grid>
        {/* Image to show on larger screens */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { xs: "none", md: "block" } }}
          style={{
            position: "relative",
            marginTop: "-40px",
            marginBottom: "-130px",
          }}
        >
          <img
            src={ScenarioLandingImage}
            style={{
              width: '100%',
              marginLeft: '-5%'
            }}
            alt="Landing image of scenarios"
          />
        </Grid>
        {/* Image to show on smaller screens */}
        <Grid
          item
          xs={12}
          md={8}
          sx={{ display: { xs: "block", md: "none" } }}
          style={{ position: "relative", marginBottom: "-110px" }}
        >
          <img
            src={ScenarioLandingImage}
            style={{
              width: '100%',
            }}
            alt="Landing image of scenarios"
          />
        </Grid>
      </Grid>

      <InformationSection
        theme={theme}
        isWelsh={isWelsh}
        informationSectionListData={ScenarioText.informationSectionListData}
      />
    </div>
  );
}

export default ShiftScenario;
