import * as React from "react";
import { Button, SvgIconProps, ButtonProps } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { Theme } from "@material-ui/core";

const ButtonFooter = styled(Button)<ButtonProps>(({ theme, height }: any) => ({
  height: height + " !important",
  color: theme.palette.text.secondary + " !important",
  backgroundColor: theme.palette.background.default + " !important",
  "&:hover": {
    backgroundColor: theme.palette.primary.main + " !important",
    color: "#fff" + " !important",
  },
  padding: "10px 25px !important",
  borderRadius: "12px" + " !important",
  marginLeft: 10,
  fontSize: "11px !important",
}));

interface StyledCustomizationProps {
  id?: string;
  sx?: any;
  icon?: React.ReactElement<SvgIconProps>;
  height?: Number;
  theme?: Theme;
  type?: "button" | "submit" | "reset" | undefined;
  onClick?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  to?: string;
  disabled?: boolean;
  children: React.ReactNode;
}

export default function ButtonFooterStyledCustomization({
  id,
  icon,
  type,
  onClick,
  to,
  sx,
  disabled,
  children,
}: StyledCustomizationProps) {
  return (
    <ButtonFooter
      id={id}
      disableElevation
      type={type}
      variant="contained"
      startIcon={icon}
      onClick={onClick}
      disabled={disabled}
      sx={sx}
      // @ts-ignore
      to={to}
      component={to ? Link : "button"}
    >
      {children}
    </ButtonFooter>
  );
}
