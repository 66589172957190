import {
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import "../App.css";
import * as ContactUsText from "../assets/texts/ContactUsText";
import HomeLandingImage from "../assets/images/HomeLandingImage.png";
import {
  LocationOnRounded,
  MailRounded,
  PhoneInTalkRounded,
} from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";
import { useState } from "react";
import ButtonPrimary from "../components/buttons/ButtonPrimary";
import ContactBg from "../assets/images/ContactBg.png";
import ContactMobile from "../assets/images/ContactMobile.png";

function ContactUs({ theme, isWelsh, setCurrentPage }: any) {
  // Form Values
  const [nameValue, setNameValue] = useState<string>("");
  const [phoneValue, setPhoneValue] = useState<string>("");
  const [emailValue, setEmailValue] = useState<string>("");
  const [orgValue, setOrgValue] = useState<string>("");
  const [messageValue, setMessageValue] = useState<string>("");
  const [nameErrorValue, setNameErrorValue] = useState<string>("");
  const [phoneErrorValue, setPhoneErrorValue] = useState<string>("");
  const [emailErrorValue, setEmailErrorValue] = useState<string>("");
  const [orgErrorValue, setOrgErrorValue] = useState<string>("");
  const [messageErrorValue, setMessageErrorValue] = useState<string>("");
  const [formError, setFormError] = useState<boolean>(true);
  const matches = useMediaQuery("(min-width:900px)");

  // Form methods
  const onTextChange = (e: any, setter: any) => {
    setter(e.target.value);
    let phoneNumRegex = new RegExp(
      /^(?:(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|\#)\d{3,4})?$/
    );
    let emailRegex = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    let orgRegex = new RegExp(/^[a-zA-Z ]{3,40}$/);
    let nameRegex = new RegExp(/^[a-zA-Z ]{3,30}$/);
    let msgRegex = new RegExp(/^[a-zA-Z ]{5,50}$/);
    console.log(
      nameRegex.test(nameValue),
      phoneNumRegex.test(phoneValue),
      orgRegex.test(orgValue)
    );

    if (!nameRegex.test(nameValue)) {
      if (!isWelsh) {
        setNameErrorValue(
          "Name must be made up of A-Z letters only between 3 - 30 characters in length."
        );
      } else {
        setNameErrorValue("Welsh name error");
      }
      setFormError(true);
    } else {
      setNameErrorValue("");
      console.log("name validated");

      if (!phoneNumRegex.test(phoneValue)) {
        if (!isWelsh) {
          setPhoneErrorValue(
            "Phone number must be a valid UK mobile phone number e.g 07123456789"
          );
        } else {
          setPhoneErrorValue("Phone number welsh error");
        }
        setFormError(true);
      } else {
        setPhoneErrorValue("");
        console.log("Phone number validated");

        if (!emailRegex.test(emailValue)) {
          if (!isWelsh) {
            setEmailErrorValue(
              "Email address must be a valid format e.g info@virtustech.co.uk"
            );
          } else {
            setEmailErrorValue("Welsh email error");
          }
          setFormError(true);
        } else {
          setEmailErrorValue("");
          if (!orgRegex.test(orgValue)) {
            if (!isWelsh) {
              setOrgErrorValue(
                "Organisation name must be made up of A-Z letters between 3-40 characters in length."
              );
            } else {
              setOrgErrorValue("Organisation welsh error");
            }
            setFormError(true);
          } else {
            setOrgErrorValue("");

            if (!msgRegex.test(messageValue)) {
              if (!isWelsh) {
                setMessageErrorValue(
                  "Message must be made up of A-Z letters between 5-50 characters in length."
                );
              } else {
                setMessageErrorValue("Message welsh error");
              }
              setFormError(true);
            } else {
              setMessageErrorValue("");
              setFormError(false);
            }
          }
        }
      }
    }
  };
  const handleSubmit = () => {
    let form: any = window.document.getElementById("form");
    if (form) {
      form.submit();
    }
  };

  return (
    <div className="App">
      <Grid container style={{ marginTop: 84 }}>
        {/* ----------------------------------------------------------------------------------------------------- */}
        {/* Landing section */}
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          style={{
            backgroundImage: `url(${ContactBg})`,
          }}
        >
          <Grid
            item
            xs={4}
            md={4}
            style={{ marginRight: matches ? "20%" : 0, minHeight: 500 }}
          >
            <Typography
              variant="h4"
              align="left"
              color={theme.palette.text.secondary}
              sx={{ fontWeight: 800, marginTop: 18 }}
            >
              {isWelsh
                ? ContactUsText.ContactUsLandingTitle.welsh
                : ContactUsText.ContactUsLandingTitle.english}
            </Typography>
            <Typography
              variant="body2"
              align="justify"
              color={theme.palette.text.secondary}
              sx={{ marginTop: 3 }}
            >
              {isWelsh
                ? ContactUsText.ContactUsLandingText.welsh
                : ContactUsText.ContactUsLandingText.english}
            </Typography>
          </Grid>
          {!matches && (
            <Grid item xs={6}>
              <img
                src={ContactMobile}
                style={{
                  width: "70vw",
                  margin: -100,
                  marginBottom: -150,
                  marginRight: -200,
                }}
                alt="contact moblie"
              />
            </Grid>
          )}
        </Grid>

        {/* ------------------------------------------------------------------------------------------------ */}
        {/* form */}
        <Grid container justifyContent={"center"}>
          <Grid item xs={9}>
            {matches ? (
              <Paper
                elevation={20}
                sx={{
                  background:
                    "linear-gradient(to left, " +
                    theme.palette.primary.dark +
                    " 50%, " +
                    theme.palette.secondary.main +
                    " 50%);",
                  marginBlock: 5,
                  borderRadius: 10,
                }}
              >
                <Grid container justifyContent={"center"}>
                  {/* --------------------------------------------------------------------------------- */}
                  {/* Form Links */}
                  <Grid item md={5}>
                    <Grid container>
                      {/* --------------------------------------------------------------------------------- */}
                      {/* Mail Link */}
                      <Grid
                        item
                        xs={12}
                        style={{ marginBlock: 30, marginTop: 140 }}
                      >
                        <Button
                          startIcon={
                            <MailRounded
                              fontSize="inherit"
                              style={{
                                color: theme.palette.text.secondary,
                                width: 50,
                                height: 50,
                              }}
                            />
                          }
                          href={ContactUsText.ContactUsLinkMail.link}
                        >
                          <Typography
                            variant="h5"
                            align="justify"
                            style={{ textTransform: "capitalize" }}
                            color={theme.palette.text.secondary}
                          >
                            {isWelsh
                              ? ContactUsText.ContactUsLinkMail.welsh
                              : ContactUsText.ContactUsLinkMail.english}
                          </Typography>
                        </Button>
                      </Grid>
                      {/* --------------------------------------------------------------------------------- */}
                      {/* Phone link */}
                      <Grid item xs={12} style={{ marginBlock: 30 }}>
                        <Button
                          startIcon={
                            <PhoneInTalkRounded
                              style={{
                                color: theme.palette.text.secondary,
                                width: 50,
                                height: 50,
                              }}
                            />
                          }
                          href={ContactUsText.ContactUsLinkPhone.link}
                        >
                          <Typography
                            variant="h5"
                            align="justify"
                            style={{ textTransform: "capitalize" }}
                            color={theme.palette.text.secondary}
                          >
                            {isWelsh
                              ? ContactUsText.ContactUsLinkPhone.welsh
                              : ContactUsText.ContactUsLinkPhone.english}
                          </Typography>
                        </Button>
                      </Grid>
                      {/* --------------------------------------------------------------------------------- */}
                      {/* Location link */}
                      <Grid item xs={12} style={{ marginBlock: 30 }}>
                        <Button
                          startIcon={
                            <LocationOnRounded
                              style={{
                                color: theme.palette.text.secondary,
                                width: 50,
                                height: 50,
                              }}
                            />
                          }
                          href={ContactUsText.ContactUsLinkLocation.link}
                        >
                          <Typography
                            variant="h5"
                            align="left"
                            style={{ textTransform: "capitalize" }}
                            color={theme.palette.text.secondary}
                          >
                            {isWelsh
                              ? ContactUsText.ContactUsLinkLocation.welsh
                              : ContactUsText.ContactUsLinkLocation.english}
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* --------------------------------------------------------------------------------- */}
                  {/* Message form */}
                  <Grid item md={5}>
                    <form
                      data-netlify="true"
                      name={"requestForm"}
                      className={"requestForm noPadding"}
                      method={"post"}
                    >
                      <input
                        type="hidden"
                        name="form-name"
                        value="requestForm"
                      />
                      <Grid
                        container
                        justifyContent={"center"}
                        style={{ marginLeft: "10%", marginTop: 20 }}
                      >
                        {/* --------------------------------------------------------------------------------- */}
                        {/* form tile  */}
                        <Grid item xs={12} style={{ marginBlock: 10 }}>
                          <Typography
                            variant="h6"
                            align="justify"
                            color={theme.palette.text.secondary}
                            style={{ fontWeight: 600 }}
                          >
                            {isWelsh
                              ? ContactUsText.ContactUsFormTitle.welsh
                              : ContactUsText.ContactUsFormTitle.english}
                          </Typography>
                        </Grid>

                        {/* --------------------------------------------------------------------------------- */}
                        {/* Name input */}
                        <Grid item xs={12} style={{ marginBlock: 10 }}>
                          <Typography
                            align="justify"
                            color={theme.palette.text.secondary}
                          >
                            {isWelsh
                              ? ContactUsText.ContactUsFormName.welsh
                              : ContactUsText.ContactUsFormName.english}
                            <span style={{ color: "red !important" }}> *</span>
                          </Typography>
                          <TextField
                            required
                            name={"name"}
                            placeholder={
                              isWelsh
                                ? ContactUsText.ContactUsFormName
                                    .placeHolderWelsh
                                : ContactUsText.ContactUsFormName
                                    .placeHolderEnglish
                            }
                            sx={{
                              background: "white",
                              input: { color: theme.palette.text.secondary },
                              width: "100%",
                            }}
                            onChange={(e: any) => onTextChange(e, setNameValue)}
                            value={nameValue}
                          />
                          {nameErrorValue !== "" && (
                            <span style={{ color: "red !important" }}>
                              <Typography
                                variant={"body1"}
                                color={"inherit"}
                                fontStyle={{ color: "red !important" }}
                              >
                                {nameErrorValue}
                              </Typography>
                            </span>
                          )}
                        </Grid>

                        {/* --------------------------------------------------------------------------------- */}
                        {/* Phone input */}
                        <Grid container justifyContent={"space-between"}>
                          <Grid item xs={5} style={{ marginBlock: 10 }}>
                            <Typography
                              align="justify"
                              color={theme.palette.text.secondary}
                            >
                              {isWelsh
                                ? ContactUsText.ContactUsFormPhone.welsh
                                : ContactUsText.ContactUsFormPhone.english}
                              <span style={{ color: "red !important" }}>
                                {" "}
                                *
                              </span>
                            </Typography>
                            <TextField
                              name={"phone"}
                              placeholder={
                                isWelsh
                                  ? ContactUsText.ContactUsFormPhone
                                      .placeHolderWelsh
                                  : ContactUsText.ContactUsFormPhone
                                      .placeHolderEnglish
                              }
                              required
                              sx={{
                                background: "white",
                                input: { color: theme.palette.text.secondary },
                                width: "100%",
                              }}
                              onChange={(e: any) =>
                                onTextChange(e, setPhoneValue)
                              }
                              value={phoneValue}
                            />
                            {phoneErrorValue !== "" && (
                              <span style={{ color: "red !important" }}>
                                <Typography
                                  fontStyle={{ color: "red !important" }}
                                >
                                  {phoneErrorValue}
                                </Typography>
                              </span>
                            )}
                          </Grid>

                          {/* --------------------------------------------------------------------------------- */}
                          {/* mail input */}
                          <Grid item xs={6} style={{ marginBlock: 10 }}>
                            <Typography
                              align="justify"
                              color={theme.palette.text.secondary}
                            >
                              {isWelsh
                                ? ContactUsText.ContactUsFormEmail.welsh
                                : ContactUsText.ContactUsFormEmail.english}
                              <span style={{ color: "red !important" }}>
                                {" "}
                                *
                              </span>
                            </Typography>
                            <TextField
                              name={"email"}
                              placeholder={
                                isWelsh
                                  ? ContactUsText.ContactUsFormEmail
                                      .placeHolderWelsh
                                  : ContactUsText.ContactUsFormEmail
                                      .placeHolderEnglish
                              }
                              required
                              sx={{
                                background: "white",
                                input: { color: theme.palette.text.secondary },
                                width: "100%",
                              }}
                              onChange={(e: any) =>
                                onTextChange(e, setEmailValue)
                              }
                              value={emailValue}
                            />
                            {emailErrorValue !== "" && (
                              <span style={{ color: "red !important" }}>
                                <Typography
                                  fontStyle={{ color: "red !important" }}
                                >
                                  {emailErrorValue}
                                </Typography>
                              </span>
                            )}
                          </Grid>
                        </Grid>

                        {/* --------------------------------------------------------------------------------- */}
                        {/* Org input */}
                        <Grid item xs={12} style={{ marginBlock: 10 }}>
                          <Typography
                            align="justify"
                            color={theme.palette.text.secondary}
                          >
                            {isWelsh
                              ? ContactUsText.ContactUsFormOrg.welsh
                              : ContactUsText.ContactUsFormOrg.english}
                          </Typography>
                          <TextField
                            name={"org"}
                            placeholder={
                              isWelsh
                                ? ContactUsText.ContactUsFormOrg
                                    .placeHolderWelsh
                                : ContactUsText.ContactUsFormOrg
                                    .placeHolderEnglish
                            }
                            sx={{
                              background: "white",
                              input: { color: theme.palette.text.secondary },
                              width: "100%",
                            }}
                            onChange={(e: any) => onTextChange(e, setOrgValue)}
                            value={orgValue}
                          />
                          {orgErrorValue !== "" && (
                            <span style={{ color: "red !important" }}>
                              <Typography
                                fontStyle={{ color: "red !important" }}
                              >
                                {orgErrorValue}
                              </Typography>
                            </span>
                          )}
                        </Grid>

                        {/* --------------------------------------------------------------------------------- */}
                        {/* Message input */}
                        <Grid item xs={12} style={{ marginBlock: 10 }}>
                          <Typography
                            align="justify"
                            color={theme.palette.text.secondary}
                          >
                            {isWelsh
                              ? ContactUsText.ContactUsFormMessage.welsh
                              : ContactUsText.ContactUsFormMessage.english}
                            <span style={{ color: "red !important" }}> *</span>
                          </Typography>
                          <TextField
                            name={"message"}
                            placeholder={
                              isWelsh
                                ? ContactUsText.ContactUsFormMessage
                                    .placeHolderWelsh
                                : ContactUsText.ContactUsFormMessage
                                    .placeHolderEnglish
                            }
                            required
                            sx={{
                              background: "white",
                              input: { color: theme.palette.text.secondary },
                              width: "100%",
                            }}
                            onChange={(e: any) =>
                              onTextChange(e, setMessageValue)
                            }
                            value={messageValue}
                          />
                          {messageErrorValue !== "" && (
                            <span style={{ color: "red !important" }}>
                              <Typography
                                fontStyle={{ color: "red !important" }}
                              >
                                {messageErrorValue}
                              </Typography>
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={8} style={{ marginBlock: 25 }}>
                          {formError && (
                            <Typography
                              align="justify"
                              color={theme.palette.text.secondary}
                            >
                              <span style={{ color: "red !important" }}>
                                {" "}
                                *{" "}
                              </span>
                              {isWelsh
                                ? ContactUsText.ContactUsFormError.welsh
                                : ContactUsText.ContactUsFormError.english}
                            </Typography>
                          )}
                          <ButtonPrimary
                            sx={{ width: "100%" }}
                            disabled={formError}
                            // type="submit"
                            onClick={handleSubmit}
                          >
                            <span style={{ fontWeight: 800 }}>
                              {isWelsh
                                ? ContactUsText.ContactUsFormSubmit.welsh
                                : ContactUsText.ContactUsFormSubmit.english}
                            </span>
                          </ButtonPrimary>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
              </Paper>
            ) : (
              <>
                <Paper
                  elevation={20}
                  sx={{
                    background: theme.palette.primary.dark,
                    marginBlock: 5,
                    borderRadius: 10,
                  }}
                >
                  <Grid container justifyContent={"center"}>
                    {/* --------------------------------------------------------------------------------- */}
                    {/* Message form */}
                    <Grid item xs={10}>
                      <form
                        data-netlify="true"
                        name={"requestForm"}
                        className={"requestForm noPadding"}
                        method={"post"}
                      >
                        <input
                          type="hidden"
                          name="form-name"
                          value="requestForm"
                        />
                        <Grid
                          container
                          justifyContent={"center"}
                          style={{ marginTop: 20 }}
                        >
                          {/* --------------------------------------------------------------------------------- */}
                          {/* form tile  */}
                          <Grid item xs={12} style={{ marginBlock: 10 }}>
                            <Typography
                              variant="h6"
                              align="justify"
                              color={theme.palette.text.secondary}
                              style={{ fontWeight: 600 }}
                            >
                              {isWelsh
                                ? ContactUsText.ContactUsFormTitle.welsh
                                : ContactUsText.ContactUsFormTitle.english}
                            </Typography>
                          </Grid>

                          {/* --------------------------------------------------------------------------------- */}
                          {/* Name input */}
                          <Grid item xs={12} style={{ marginBlock: 10 }}>
                            <Typography
                              align="justify"
                              color={theme.palette.text.secondary}
                            >
                              {isWelsh
                                ? ContactUsText.ContactUsFormName.welsh
                                : ContactUsText.ContactUsFormName.english}
                              <span style={{ color: "red !important" }}>
                                {" "}
                                *
                              </span>
                            </Typography>
                            <TextField
                              required
                              name={"name"}
                              placeholder={
                                isWelsh
                                  ? ContactUsText.ContactUsFormName
                                      .placeHolderWelsh
                                  : ContactUsText.ContactUsFormName
                                      .placeHolderEnglish
                              }
                              sx={{
                                background: "white",
                                input: { color: theme.palette.text.secondary },
                                width: "100%",
                              }}
                              onChange={(e: any) =>
                                onTextChange(e, setNameValue)
                              }
                              value={nameValue}
                            />
                            {nameErrorValue !== "" && (
                              <span style={{ color: "red !important" }}>
                                <Typography
                                  variant={"body1"}
                                  color={"inherit"}
                                  fontStyle={{ color: "red !important" }}
                                >
                                  {nameErrorValue}
                                </Typography>
                              </span>
                            )}
                          </Grid>

                          {/* --------------------------------------------------------------------------------- */}
                          {/* Phone input */}
                          <Grid container justifyContent={"space-between"}>
                            <Grid item xs={5} style={{ marginBlock: 10 }}>
                              <Typography
                                align="justify"
                                color={theme.palette.text.secondary}
                              >
                                {isWelsh
                                  ? ContactUsText.ContactUsFormPhone.welsh
                                  : ContactUsText.ContactUsFormPhone.english}
                                <span style={{ color: "red !important" }}>
                                  {" "}
                                  *
                                </span>
                              </Typography>
                              <TextField
                                name={"phone"}
                                placeholder={
                                  isWelsh
                                    ? ContactUsText.ContactUsFormPhone
                                        .placeHolderWelsh
                                    : ContactUsText.ContactUsFormPhone
                                        .placeHolderEnglish
                                }
                                required
                                sx={{
                                  background: "white",
                                  input: {
                                    color: theme.palette.text.secondary,
                                  },
                                  width: "100%",
                                }}
                                onChange={(e: any) =>
                                  onTextChange(e, setPhoneValue)
                                }
                                value={phoneValue}
                              />
                              {phoneErrorValue !== "" && (
                                <span style={{ color: "red !important" }}>
                                  <Typography
                                    fontStyle={{ color: "red !important" }}
                                  >
                                    {phoneErrorValue}
                                  </Typography>
                                </span>
                              )}
                            </Grid>

                            {/* --------------------------------------------------------------------------------- */}
                            {/* mail input */}
                            <Grid item xs={6} style={{ marginBlock: 10 }}>
                              <Typography
                                align="justify"
                                color={theme.palette.text.secondary}
                              >
                                {isWelsh
                                  ? ContactUsText.ContactUsFormEmail.welsh
                                  : ContactUsText.ContactUsFormEmail.english}
                                <span style={{ color: "red !important" }}>
                                  {" "}
                                  *
                                </span>
                              </Typography>
                              <TextField
                                name={"email"}
                                placeholder={
                                  isWelsh
                                    ? ContactUsText.ContactUsFormEmail
                                        .placeHolderWelsh
                                    : ContactUsText.ContactUsFormEmail
                                        .placeHolderEnglish
                                }
                                required
                                sx={{
                                  background: "white",
                                  input: {
                                    color: theme.palette.text.secondary,
                                  },
                                  width: "100%",
                                }}
                                onChange={(e: any) =>
                                  onTextChange(e, setEmailValue)
                                }
                                value={emailValue}
                              />
                              {emailErrorValue !== "" && (
                                <span style={{ color: "red !important" }}>
                                  <Typography
                                    fontStyle={{ color: "red !important" }}
                                  >
                                    {emailErrorValue}
                                  </Typography>
                                </span>
                              )}
                            </Grid>
                          </Grid>

                          {/* --------------------------------------------------------------------------------- */}
                          {/* Org input */}
                          <Grid item xs={12} style={{ marginBlock: 10 }}>
                            <Typography
                              align="justify"
                              color={theme.palette.text.secondary}
                            >
                              {isWelsh
                                ? ContactUsText.ContactUsFormOrg.welsh
                                : ContactUsText.ContactUsFormOrg.english}
                            </Typography>
                            <TextField
                              name={"org"}
                              placeholder={
                                isWelsh
                                  ? ContactUsText.ContactUsFormOrg
                                      .placeHolderWelsh
                                  : ContactUsText.ContactUsFormOrg
                                      .placeHolderEnglish
                              }
                              sx={{
                                background: "white",
                                input: { color: theme.palette.text.secondary },
                                width: "100%",
                              }}
                              onChange={(e: any) =>
                                onTextChange(e, setOrgValue)
                              }
                              value={orgValue}
                            />
                            {orgErrorValue !== "" && (
                              <span style={{ color: "red !important" }}>
                                <Typography
                                  fontStyle={{ color: "red !important" }}
                                >
                                  {orgErrorValue}
                                </Typography>
                              </span>
                            )}
                          </Grid>

                          {/* --------------------------------------------------------------------------------- */}
                          {/* Message input */}
                          <Grid item xs={12} style={{ marginBlock: 10 }}>
                            <Typography
                              align="justify"
                              color={theme.palette.text.secondary}
                            >
                              {isWelsh
                                ? ContactUsText.ContactUsFormMessage.welsh
                                : ContactUsText.ContactUsFormMessage.english}
                              <span style={{ color: "red !important" }}>
                                {" "}
                                *
                              </span>
                            </Typography>
                            <TextField
                              name={"message"}
                              placeholder={
                                isWelsh
                                  ? ContactUsText.ContactUsFormMessage
                                      .placeHolderWelsh
                                  : ContactUsText.ContactUsFormMessage
                                      .placeHolderEnglish
                              }
                              required
                              sx={{
                                background: "white",
                                input: { color: theme.palette.text.secondary },
                                width: "100%",
                              }}
                              onChange={(e: any) =>
                                onTextChange(e, setMessageValue)
                              }
                              value={messageValue}
                            />
                            {messageErrorValue !== "" && (
                              <span style={{ color: "red !important" }}>
                                <Typography
                                  fontStyle={{ color: "red !important" }}
                                >
                                  {messageErrorValue}
                                </Typography>
                              </span>
                            )}
                          </Grid>
                          <Grid item xs={8} style={{ marginBlock: 25 }}>
                            {formError && (
                              <Typography
                                align="justify"
                                color={theme.palette.text.secondary}
                              >
                                <span style={{ color: "red !important" }}>
                                  {" "}
                                  *{" "}
                                </span>
                                {isWelsh
                                  ? ContactUsText.ContactUsFormError.welsh
                                  : ContactUsText.ContactUsFormError.english}
                              </Typography>
                            )}
                            <ButtonPrimary
                              sx={{ width: "100%" }}
                              disabled={formError}
                              // type="submit"
                              onClick={handleSubmit}
                            >
                              <span style={{ fontWeight: 800 }}>
                                {isWelsh
                                  ? ContactUsText.ContactUsFormSubmit.welsh
                                  : ContactUsText.ContactUsFormSubmit.english}
                              </span>
                            </ButtonPrimary>
                          </Grid>
                        </Grid>
                      </form>
                    </Grid>
                  </Grid>
                </Paper>
                <Paper
                  elevation={20}
                  sx={{
                    background: theme.palette.secondary.main,
                    marginBlock: 5,
                    borderRadius: 10,
                  }}
                >
                  <Grid container justifyContent={"center"}>
                    {/* --------------------------------------------------------------------------------- */}
                    {/* Form Links */}
                    <Grid item md={10}>
                      <Grid container>
                        {/* --------------------------------------------------------------------------------- */}
                        {/* Mail Link */}
                        <Grid
                          item
                          xs={12}
                          style={{
                            marginBlock: 30,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            style={{ flexDirection: "column" }}
                            startIcon={
                              <MailRounded
                                fontSize="inherit"
                                style={{
                                  color: theme.palette.text.secondary,
                                  width: 50,
                                  height: 50,
                                }}
                              />
                            }
                            href={ContactUsText.ContactUsLinkMail.link}
                          >
                            <Typography
                              variant="h5"
                              align="justify"
                              style={{ textTransform: "capitalize" }}
                              color={theme.palette.text.secondary}
                            >
                              {isWelsh
                                ? ContactUsText.ContactUsLinkMail.welsh
                                : ContactUsText.ContactUsLinkMail.english}
                            </Typography>
                          </Button>
                        </Grid>
                        {/* --------------------------------------------------------------------------------- */}
                        {/* Phone link */}
                        <Grid
                          item
                          xs={12}
                          style={{
                            marginBlock: 30,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            style={{ flexDirection: "column" }}
                            startIcon={
                              <PhoneInTalkRounded
                                style={{
                                  color: theme.palette.text.secondary,
                                  width: 50,
                                  height: 50,
                                }}
                              />
                            }
                            href={ContactUsText.ContactUsLinkPhone.link}
                          >
                            <Typography
                              variant="h5"
                              align="justify"
                              style={{ textTransform: "capitalize" }}
                              color={theme.palette.text.secondary}
                            >
                              {isWelsh
                                ? ContactUsText.ContactUsLinkPhone.welsh
                                : ContactUsText.ContactUsLinkPhone.english}
                            </Typography>
                          </Button>
                        </Grid>
                        {/* --------------------------------------------------------------------------------- */}
                        {/* Location link */}
                        <Grid
                          item
                          xs={12}
                          style={{
                            marginBlock: 30,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            style={{ flexDirection: "column" }}
                            href={ContactUsText.ContactUsLinkLocation.link}
                            startIcon={
                              <LocationOnRounded
                                style={{
                                  color: theme.palette.text.secondary,
                                  width: 50,
                                  height: 50,
                                }}
                              />
                            }
                          >
                            <Typography
                              variant="h5"
                              align="left"
                              style={{ textTransform: "capitalize" }}
                              color={theme.palette.text.secondary}
                            >
                              {isWelsh
                                ? ContactUsText.ContactUsLinkLocation.welsh
                                : ContactUsText.ContactUsLinkLocation.english}
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default ContactUs;
