import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { isPropertySignature } from "typescript";
import AppTheme from "../assets/theme/theme";
import NavBar from "../components/navBar/NavBar";
import Footer from "../components/footer/AppFooter";

import Home from "./Home";
import PatientJourney from "./PatientJourney";
import ShiftScenario from "./ShiftScenario";
import TimedScenario from "./TimedScenario";
import AppFooter from "../components/footer/AppFooter";

import Blog from "./Blog";
import ContactUs from "./ContactUs";
import CreatorsMode from "./CreatorsMode";

export default function AppRoutes(props: any) {
  const [isWelsh, setIsWelsh] = useState(false);
  const [currentPage, setCurrentPage] = useState("");

  return (
    <BrowserRouter>
      <NavBar
        theme={props.theme}
        currentPage={currentPage}
        isWelsh={isWelsh}
        setIsWelsh={setIsWelsh}
      />
      <Routes>
        <Route
          path="/"
          element={
            <Home
              theme={props.theme}
              isWelsh={isWelsh}
              setIsWelsh={setIsWelsh}
              setCurrentPage={setCurrentPage}
            />
          }
        ></Route>

        <Route
          path="/Shift-Scenario"
          element={
            <ShiftScenario
              theme={props.theme}
              isWelsh={isWelsh}
              setIsWelsh={setIsWelsh}
              setCurrentPage={setCurrentPage}
            />
          }
        ></Route>
        <Route
          path="/Patient-Journey"
          element={
            <PatientJourney
              theme={props.theme}
              isWelsh={isWelsh}
              setIsWelsh={setIsWelsh}
              setCurrentPage={setCurrentPage}
            />
          }
        ></Route>
        <Route
          path="/Timed-Scenario"
          element={
            <TimedScenario
              theme={props.theme}
              isWelsh={isWelsh}
              setIsWelsh={setIsWelsh}
              setCurrentPage={setCurrentPage}
            />
          }
        ></Route>
        <Route
          path="/Customise"
          element={
            <Home
              theme={props.theme}
              isWelsh={isWelsh}
              setIsWelsh={setIsWelsh}
              setCurrentPage={setCurrentPage}
            />
          }
        ></Route>
        <Route
          path="/Creator-Mode"
          element={
            <CreatorsMode
              theme={props.theme}
              isWelsh={isWelsh}
              setIsWelsh={setIsWelsh}
              setCurrentPage={setCurrentPage}
            />
          }
        ></Route>

        <Route
          path="/TimeLine"
          element={
            <Home
              theme={props.theme}
              isWelsh={isWelsh}
              setIsWelsh={setIsWelsh}
              setCurrentPage={setCurrentPage}
            />
          }
        ></Route>

        <Route
          path="/Blog"
          element={
            <Blog
              theme={props.theme}
              isWelsh={isWelsh}
              setIsWelsh={setIsWelsh}
              setCurrentPage={setCurrentPage}
            />
          }
        ></Route>

        <Route
          path="/Contact-Us"
          element={
            <ContactUs
              theme={props.theme}
              isWelsh={isWelsh}
              setIsWelsh={setIsWelsh}
              setCurrentPage={setCurrentPage}
            />
          }
        ></Route>
        <Route path="/Join-Us" element={<Home theme={props.theme} />}></Route>

        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Routes>

      <AppFooter isWelsh={isWelsh} />
    </BrowserRouter>
  );
}
