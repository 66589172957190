import * as React from "react";
import { Paper, SvgIconProps, PaperProps } from "@mui/material";

import { alpha, styled } from "@mui/material/styles";

const PaperSecondary = styled(Paper)<PaperProps>((props: any) => ({
  width: "100%",
  // height: 50,
  backgroundColor: props.theme.palette.secondary.main,
  boxShadow: "none"
}));

interface StyledCustomizationProps {
  children?: React.ReactChild;
  sx?: any;
}

export default function PaperSecondaryStyledCustomization({
  children,
  sx,
}: StyledCustomizationProps) {
  return <PaperSecondary sx={sx}>{children}</PaperSecondary>;
}
