import React, { useState, useEffect, Fragment } from "react";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ButtonPrimaryStyledCustomization from "../buttons/ButtonPrimary";
import ButtonSecondaryStyledCustomization from "../buttons/ButtonSecondary";
import ButtonNavStyledCustomization from "../buttons/ButtonNav";
import "../../App.css";
import AppNavBarStyledCustomization from "./AppNavBar";
import { Switch } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { ArrowDropDown } from "@mui/icons-material";

//Theme = app theme
//Current page = 'Home' || 'Scenarios', etc. to tell NavBar which button is currently selected
//isWelsh/ setIsWelsh -  State management for showing english/welsh text
function NavBar({ theme, currentPage, isWelsh, setIsWelsh }: any) {
  //States to handle NavBar
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  //States to handle Scenario Dropdown
  const [scenarioElNav, setScenarioElNav] = React.useState<null | HTMLElement>(
    null
  );

  //Use navigate to redirect programmatically
  const navigate = useNavigate();
  //Mobile nav icon color
  const [color, setColor] = useState<any>("primary");
  //Pages that are in the navbar along with selected booleans.
  // TYPE = LINK
  //// ID required, Name required, Selected bool required, Link required (AppRoutes link)
  //TYPE = PARENT
  //// ID required, Name required, Selected bool required,
  const [pages, setPages] = useState([
    { id: 1, name: "Home", selected: false, link: "/", type: "link" },
    {
      id: 2,
      name: "Scenarios",
      selected: false,
      link: "",
      type: "parent",
      parentId: "scenarios",
    },
    {
      id: 3,
      name: "Shift Scenario",
      selected: false,
      link: "/Shift-Scenario",
      type: "child",
      parentId: "scenarios",
    },
    {
      id: 4,
      name: "Patient Journey",
      selected: false,
      link: "/Patient-Journey",
      type: "child",
      parentId: "scenarios",
    },
    {
      id: 5,
      name: "Timed Scenario",
      selected: false,
      link: "/Timed-Scenario",
      type: "child",
      parentId: "scenarios",
    },
    {
      id: 6,
      name: "Creator Mode",
      selected: false,
      link: "/Creator-Mode",
      type: "child",
      parentId: "scenarios",
    },
    { id: 7, name: "Blog", selected: false, link: "/Blog", type: "link" },
    {
      id: 8,
      name: "Contact Us",
      selected: false,
      link: "/Contact-us",
      type: "link",
    },
  ]);

  function setPageInfo(page_new: any, id: number) {
    let all_pages = pages.filter((val: any) => val.id !== id);
    all_pages.push(page_new);
    all_pages.sort((a: any, b: any) => (a.id > b.id ? 1 : -1));

    setPages(all_pages);
  }

  // Get current page from the parent component, set currently selected bool to true for page in page list.
  useEffect(() => {
    //Make all unselected upon change of page, before setting new selected page
    let pages_unselected: any = pages;
    for (let i = 0; i < pages.length; i++) {
      pages_unselected[i].selected = false;
    }

    const tempPages: any = pages_unselected.filter(
      (val: any) => val.name !== currentPage
    );
    const tempPage = pages_unselected.find(
      (val: any) => val.name === currentPage
    );

    if (tempPage) {
      tempPage.selected = true;
      tempPages.push(tempPage);
      tempPages.sort((a: any, b: any) => (a.id > b.id ? 1 : -1));
      setPages(tempPages);
    }
  }, [currentPage]);

  //Functions to handle navbar
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleHoverOnNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setColor(theme.palette.primary.main);
  };

  const handleHoverOffNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setColor("primary");
  };

  const handleCloseNavMenu = (link: string) => {
    navigate(link);
    setAnchorElNav(null);
    setScenarioElNav(null);
  };

  //Functions to handle scenario dropdown
  const handleOpenScenarioMenu = (event: React.MouseEvent<HTMLElement>) => {
    setScenarioElNav(event.currentTarget);
  };

  const handleCloseScenarioMenu = () => {
    setScenarioElNav(null);
  };

  return (
    // Custom AppBar
    <AppNavBarStyledCustomization>
      <Grid container alignItems={"center"} justifyContent={"space-between"}>
        <>
          {/* Large navigation buttons - */}
          <Box
            sx={{
              display: { xs: "none", md: "block" },
            }}
          >
            {pages.map((page) => {
              if (page.type === "link") {
                return (
                  <Fragment key={page.name}>
                    {page.selected ? (
                      <ButtonPrimaryStyledCustomization
                        theme={theme}
                        height={30}
                        key={page.name}
                        to={page.link}
                      >
                        {page.name}
                      </ButtonPrimaryStyledCustomization>
                    ) : (
                      <ButtonSecondaryStyledCustomization
                        theme={theme}
                        height={30}
                        key={page.name}
                        to={page.link}
                      >
                        {page.name}
                      </ButtonSecondaryStyledCustomization>
                    )}
                  </Fragment>
                );
              } else if (page.type === "parent") {
                //Must be parent
                return (
                  <>
                    <ButtonSecondaryStyledCustomization
                      theme={theme}
                      height={30}
                      key={page.name}
                      onClick={handleOpenScenarioMenu}
                    >
                      {page.name}
                      <ArrowDropDown />
                    </ButtonSecondaryStyledCustomization>

                    <Menu
                      id="menu-appbar"
                      anchorEl={scenarioElNav}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      open={Boolean(scenarioElNav)}
                      onClose={handleCloseScenarioMenu}
                      sx={{
                        display: { xs: "none", md: "flex" },
                        width: "100vw",
                        left: 0,
                      }}
                    >
                      <Grid
                        container
                        flexDirection={"column"}
                        sx={{ margin: 0, padding: "5px 10px" }}
                      >
                        {/* Filter so only children of this parent appear */}
                        {pages
                          .filter(
                            (val: any) =>
                              val.name !== page.name &&
                              val.parentId === page.parentId
                          )
                          .map((page) => (
                            <>
                              <MenuItem
                                key={page.name}
                                onClick={() => {
                                  if (page.link) {
                                    handleCloseNavMenu(page.link);
                                  }
                                }}
                                style={
                                  page.selected
                                    ? {
                                        borderLeft:
                                          "4px solid " +
                                          theme.palette.primary.main,
                                        paddingLeft: "10px",
                                        justifyContent: "flex-start",
                                        padding: "10px 20px",
                                      }
                                    : {
                                        justifyContent: "flex-start",
                                        padding: "10px 20px",
                                        paddingLeft: "5px",
                                      }
                                }
                              >
                                <Typography
                                  textAlign="center"
                                  color={
                                    page.selected
                                      ? "primary"
                                      : theme.palette.text.secondary
                                  }
                                >
                                  {page.name}
                                </Typography>
                              </MenuItem>
                            </>
                          ))}
                      </Grid>
                    </Menu>
                  </>
                );
              }
            })}
          </Box>

          <Box
            alignItems={"center"}
            sx={{
              flexGrow: 0,
              width: "30px",
              display: { xs: "block", md: "none" },
              alignItems: "center",
            }}
          >
            <ButtonNavStyledCustomization
              theme={theme}
              onClick={handleOpenNavMenu}
              onMouseOver={handleHoverOnNavMenu}
              onMouseLeave={handleHoverOffNavMenu}
              height={30}
              sx={{
                backgroundColor: theme.palette.primary.main,
                "&:hover": { color: "white" },
              }}
            >
              {Boolean(anchorElNav) ? (
                <CloseIcon
                  sx={{ color: theme.palette.primary.main, cursor: "pointer" }}
                />
              ) : (
                <MenuIcon
                  sx={{
                    color: theme.palette.primary.main,
                    cursor: "pointer",
                    "&:hover": { color: "white" },
                  }}
                />
              )}
            </ButtonNavStyledCustomization>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "flex", md: "none" },
                width: "100vw",
                position: "absolute",
                top: "64px",
                left: "0px",
                maxWidth: "100vw",
                cursor: "pointer",
              }}
            >
              <Grid
                container
                flexDirection={"column"}
                sx={{ margin: 0, padding: "5px 10px", width: "100vw" }}
              >
                {pages.map((page) => (
                  <>
                    {page.type === "link" && (
                      <MenuItem
                        key={page.name}
                        onClick={() => {
                          if (page.link) {
                            handleCloseNavMenu(page.link);
                          }
                        }}
                        style={
                          page.selected
                            ? {
                                borderLeft:
                                  "4px solid " + theme.palette.primary.main,
                                justifyContent: "flex-start",
                                padding: "10px 20px",
                                width: "100%",
                                paddingLeft: "10px",
                              }
                            : {
                                justifyContent: "flex-start",
                                padding: "10px 20px",
                                width: "100%",
                                paddingLeft: "5px",
                              }
                        }
                      >
                        <Typography
                          textAlign="center"
                          color={
                            page.selected
                              ? "primary"
                              : theme.palette.text.secondary
                          }
                        >
                          {page.name}
                        </Typography>
                      </MenuItem>
                    )}

                    {page.type === "parent" && (
                      <MenuItem
                        key={page.name}
                        style={{
                          justifyContent: "flex-start",
                          padding: "10px 20px",
                          width: "100%",
                          paddingLeft: "5px",
                        }}
                      >
                        <Typography
                          color={theme.palette.text.secondary}
                          variant={"body1"}
                        >
                          {page.name}
                        </Typography>
                      </MenuItem>
                    )}

                    {page.type === "child" && (
                      <MenuItem
                        key={page.name}
                        onClick={() => {
                          if (page.link) {
                            handleCloseNavMenu(page.link);
                          }
                        }}
                        style={
                          page.selected
                            ? {
                                borderLeft:
                                  "4px solid " + theme.palette.primary.main,
                                justifyContent: "flex-start",
                                padding: "10px 20px",
                                width: "100%",
                                paddingLeft: "25px",
                              }
                            : {
                                justifyContent: "flex-start",
                                padding: "10px 20px",
                                width: "100%",
                                paddingLeft: "20px",
                              }
                        }
                      >
                        <Typography
                          textAlign="center"
                          color={
                            page.selected
                              ? "primary"
                              : theme.palette.text.secondary
                          }
                        >
                          {page.name}
                        </Typography>
                      </MenuItem>
                    )}
                  </>
                ))}
                {/* Welsh/Eng toggle */}
                <Box sx={{ padding: "10px 20px" }}>
                  <Grid
                    container
                    item
                    flexDirection={"row"}
                    xs={12}
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{
                      minWidth: "150px",
                    }}
                  >
                    <Grid item style={{ color: theme.palette.primary.dark }}>
                      <Typography
                        color={!isWelsh ? "primary" : "inherit"}
                        variant={"body2"}
                        sx={{ fontWeight: "bold", fontSize: "11px" }}
                      >
                        ENGLISH
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Switch
                        checked={isWelsh}
                        color={"primary"}
                        onChange={() => {
                          setIsWelsh(!isWelsh);
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                    <Grid item style={{ color: theme.palette.primary.dark }}>
                      <Typography
                        color={isWelsh ? "primary" : "inherit"}
                        variant={"body2"}
                        sx={{ fontWeight: "bold", fontSize: "11px" }}
                      >
                        WELSH
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Menu>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Grid
              container
              item
              flexDirection={"row"}
              xs={6}
              alignItems={"center"}
              sx={{
                minWidth: "150px",
                marginRight: "10px",
                display: { md: "flex", xs: "none" },
              }}
            >
              <Grid item style={{ color: theme.palette.primary.dark }}>
                <Typography
                  color={!isWelsh ? "primary" : "inherit"}
                  variant={"body2"}
                  sx={{ fontWeight: "bold", fontSize: "11px" }}
                >
                  ENGLISH
                </Typography>
              </Grid>
              <Grid item>
                <Switch
                  checked={isWelsh}
                  color={"primary"}
                  onChange={() => {
                    setIsWelsh(!isWelsh);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Grid>
              <Grid item style={{ color: theme.palette.primary.dark }}>
                <Typography
                  color={isWelsh ? "primary" : "inherit"}
                  variant={"body2"}
                  sx={{ fontWeight: "bold", fontSize: "11px" }}
                >
                  WELSH
                </Typography>
              </Grid>
            </Grid>
            <img
              alt="Wales Virtual Hospital"
              style={{ height: "40px" }}
              src="images/nhs.png"
            />
          </Box>
        </>
      </Grid>
    </AppNavBarStyledCustomization>
  );
}

export default NavBar;
