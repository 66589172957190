import { Grid, Paper, Theme, Typography } from "@mui/material";
import Body from "../../assets/icons/Body";
import Dna from "../../assets/icons/Dna";
import Heart from "../../assets/icons/Heart";
import Neurology from "../../assets/icons/Neurology";
import ButtonPrimary from "../buttons/ButtonPrimary";
import ButtonSecondaryStyledCustomization from "../buttons/ButtonSecondary";

export default function CreatorCard(
  textArray: any,
  image: string,
  theme: Theme,
  isWelsh: boolean
) {
  return (
    <Grid container justifyContent={"space-evenly"} alignItems={"center"} sx={{ flexDirection: {xs: "column", lg: "row" }}}>
      <Grid container item xs={12} lg={4} justifyContent={'center'} sx={{paddingTop: '40px !important',paddingBottom: '40px !important'}}>
            {image === 'heart' ? (
              <Heart height={150} color={theme.palette.primary.main} />
            ) : image === 'dna' ? (
              <Dna height={150} color={theme.palette.primary.main} />
            ) : image === 'body' ? (
              <Body height={150} color={theme.palette.primary.main} />
            ) : image === 'neurology' && (
              <Neurology height={150} color={theme.palette.primary.main} />
            )}
      </Grid>
      <Grid
        container
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        item
        xs={12}
        lg={8}

        style={{ backgroundColor: theme.palette.primary.main, padding: '20px 30px' }}
      >
        <Grid item xs={11}>
          <Typography
            variant="h6"
            align="center"
            color={theme.palette.text.primary}
            sx={{ fontWeight: 700 }}
          >
            {isWelsh
              ? textArray.HomeCreatorsTitle.welsh
              : textArray.HomeCreatorsTitle.english}
          </Typography>
        </Grid>

        <Grid item xs={10}>
          <Typography
            variant="body2"
            align="center"
            color={theme.palette.text.primary}
            sx={{ marginInline: 4, marginBlock: 4 }}
          >
            {isWelsh
              ? textArray.HomeCreatorsText1.welsh
              : textArray.HomeCreatorsText1.english}
          </Typography>
        </Grid>

        <Grid item xs={10}>
          <Typography
            variant="body2"
            align="center"
            color={theme.palette.text.primary}
            sx={{ marginInline: 4, marginBottom: 4 }}
          >
            {isWelsh
              ? textArray.HomeCreatorsText2.welsh
              : textArray.HomeCreatorsText2.english}
          </Typography>
        </Grid>

        <Grid item xs={10} style={{ marginBottom: 50 }}>
          <ButtonSecondaryStyledCustomization
            onClick={() => {
              window.location.href = textArray.HomeCreatorsButton.link;
            }}
          >
            {isWelsh
              ? textArray.HomeCreatorsButton.welsh
              : textArray.HomeCreatorsButton.english}
          </ButtonSecondaryStyledCustomization>
        </Grid>
      </Grid>
    </Grid>
  );
}
